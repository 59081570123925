import { AjvError } from '../model/IJsonSchema';
import { IntlShape } from 'react-intl';
import { GraphQLError } from 'graphql';

const inStorePaymentFormErrorTransformer = {
  from(errors: readonly GraphQLError[], intl: IntlShape): AjvError[] {
    const validationErrors: AjvError[] = [];

    if (Array.isArray(errors)) {
      for (const error of errors) {
        let errorMessage = String(error.path) ?? '';

        if (intl.messages?.[errorMessage]) {
          errorMessage = intl.formatMessage({ id: errorMessage });
        }
        if (!errorMessage) {
          throw new Error('The validation error is not formatted correctly!');
        }

        validationErrors.push({
          message: errorMessage,
          name: error.name,
          params: null,
          property: String(error.path),
          stack: error.stack ?? '',
        });
      }
    }

    return validationErrors;
  },
};

export default inStorePaymentFormErrorTransformer;
