import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';

export type MercadoPagoContainerClasses = 'container' | 'title' | 'description' | 'description2' | 'email';

const mercadoPagoContainerStyles = makeStyles<ICheckoutTheme, MercadoPagoContainerClasses>(
  ({ palette, typography }) => ({
    container: {
      width: '100%',
      height: '65vh',
    },
    title: {
      paddingBottom: '10px',
      fontWeight: typography.fontWeightBold,
    },
    description: {
      minHeight: '50px',
      lineHeight: '40px',
      fontWeight: typography.fontWeightLight,
      display: 'block',
    },
    email: {
      display: 'inline',
      color: palette.primary.main,
      textDecoration: 'underline',
      fontSize: typography.fontMD,
    },
    description2: {
      fontSize: typography.fontMD,
      textAlign: 'center',
      marginTop: '20px',
      display: 'block',
    },
  }),
  { name: 'Gql_MercadoPagoContainer' },
);

export default mercadoPagoContainerStyles;
