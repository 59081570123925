import { ICheckoutTheme } from '../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type HeadlessFinanceSectionClasses = 'financeWrapper' | 'title' | 'description' | 'buttonContainer';

const headlessFinanceSectionStyles = makeStyles<ICheckoutTheme, HeadlessFinanceSectionClasses>(
  ({ typography, breakpoints }) => ({
    financeWrapper: {
      display: 'block',
      marginBottom: '40px',
      padding: '0 30px',
      [breakpoints.down('sm')]: {
        padding: '0 15px',
      },
    },
    title: {
      fontSize: typography.fontXL,
      fontWeight: 700,
      marginBottom: '10px',
      textTransform: 'uppercase',
    },
    description: {
      fontSize: typography.fontSM,
      marginBottom: '10px',
    },
    buttonContainer: {
      height: '40px',
      borderRadius: 'unset',
      maxWidth: 'fit-content',
      marginLeft: '10px',
    },
  }),
  { name: 'Checkout4_HeadlessFinanceSection' },
);

export default headlessFinanceSectionStyles;
