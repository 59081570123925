import * as React from 'react';
import { Button, CircularProgress, useTheme } from '@material-ui/core';
import { FunctionComponent } from 'react';
import continueButtonStyles from './continueButtonStyles';
import useJourneyStepsContext from '../../../hooks/useJourneyStepsContext';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';
import clsx from 'clsx';

interface ContinueButtonProps {
  testId: string;
  activateNextStep: boolean;
  disabled?: boolean;
  handleClick?: () => void;
  loading?: boolean;
  className?: string;
}

const ContinueButton: FunctionComponent<ContinueButtonProps> = ({
  testId,
  activateNextStep,
  disabled = false,
  handleClick,
  loading,
  className,
}) => {
  const styles = continueButtonStyles();
  const { handleNext } = useJourneyStepsContext();

  const {
    checkoutLite: { checkout4 },
  } = useTheme<ICheckoutTheme>();

  return (
    <Button
      type="button"
      variant="contained"
      color="primary"
      onClick={activateNextStep ? handleNext : handleClick}
      className={clsx(styles.continueButton, className)}
      classes={{ containedPrimary: styles.newContainedPrimary, contained: styles.newContained }}
      disabled={disabled}
      data-test={testId}
      disableTouchRipple={!!checkout4?.disableTouchRipple}
    >
      {loading && (
        <CircularProgress
          size={15}
          className={styles.loader}
          data-test="checkout:continue_button:select_journey:loader"
        />
      )}
      <Translation id="trans__checkout__next_step__continue_CTA" />
    </Button>
  );
};

export default ContinueButton;
