export enum CurrencyType {
  PL = 'pl-PL',
  FI = 'fi-FI',
  LT = 'lt-LT',
  LV = 'lv-LV',
  EE = 'et-EE',
  CO = 'es-CO',
  CL = 'es-CL',
  AR = 'es-AR',
  PE = 'es-PE',
  SG = 'en-SG',
  CR = 'es-CR',
  PA = 'es-PA',
}
