import React, { FunctionComponent, useCallback, useEffect, useState, useMemo, PropsWithChildren } from 'react';
import { IRawError, ISchemaFieldProps } from '../../model/IJsonSchema';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DatePickerInput from './DatePickerInput';
import LocaleEn from 'date-fns/locale/en-AU';
import LocaleEs from 'date-fns/locale/es';
import LocaleZh from 'date-fns/locale/zh-CN';
import useWidgetContext from '../../../../common/react/useWidgetContext';
import Translation from '../../../../common/components/presentation/Translation/Translation';
import { TextFieldProps, useTheme } from '@material-ui/core';
import { ICheckoutTheme } from 'src/common/theme/ICheckoutTheme';

export const DatePicker: FunctionComponent<ISchemaFieldProps> = ({ formData }) => {
  const { value, rawErrors, uiSchema, options } = formData;
  const disableFuture = uiSchema?.['ui:options']?.disableFuture || options?.disableFuture;
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  let currentDateValue: Date | null = null;
  if (value) {
    currentDateValue = new Date(value);
  }
  const [selectedDate, setNewDate] = useState<Date | null>(currentDateValue);
  const [errorMessage, setErrorMessage] = useState<IRawError>(null);
  const {
    checkoutLite: { checkout4 },
  } = useTheme<ICheckoutTheme>();
  const { disableCurrentToFutureDate } = checkout4 || {};

  const toggleDatePicker = useCallback((value: boolean) => setDatePickerOpen(value), [isDatePickerOpen]);

  const locales = {
    'zh-hk': LocaleZh,
    'es-ar': LocaleEs,
    'es-cl': LocaleEs,
    'es-co': LocaleEs,
    'es-pe': LocaleEs,
  };

  const { configuration } = useWidgetContext();
  // TODO: split code and load locale based on environment language
  const locale = locales[configuration.dimensions.language as keyof typeof locales] || LocaleEn;

  const handleDateChange = useCallback((selectedDate: Date) => {
    setErrorMessage(null);
    setNewDate(selectedDate);
  }, []);

  const disableCurrentToOrFutureDate = useMemo(() => {
    const currentDate = new Date();
    return disableCurrentToFutureDate ? currentDate.setDate(new Date().getDate() - 1) : currentDate;
  }, [disableCurrentToFutureDate]);

  useEffect(() => {
    const currentError = rawErrors?.length > 0 && rawErrors[0];
    if (!!currentError) {
      setErrorMessage(currentError);
    }
  }, [rawErrors]);

  return (
    <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
      <KeyboardDatePicker
        clearable={true}
        open={isDatePickerOpen}
        onClose={() => toggleDatePicker(false)}
        value={selectedDate}
        onChange={handleDateChange}
        disableFuture={disableFuture}
        minDate={disableFuture ? undefined : new Date()}
        maxDate={disableFuture ? disableCurrentToOrFutureDate : undefined}
        format="yyyy/MM/dd"
        inputProps={{
          'data-test': disableFuture ? 'checkout:enquiry:datepicker:disableFuture' : undefined,
        }}
        TextFieldComponent={(props: PropsWithChildren<TextFieldProps>) =>
          DatePickerInput({
            schemaField: { props, formData },
            toggleDatePicker,
            errorMessage,
          })
        }
        okLabel={<Translation id="trans__checkout__enquiry_form__date_ok_button" />}
        cancelLabel={<Translation id="trans__checkout__enquiry_form__date_cancel_button" />}
        clearLabel={<Translation id="trans__checkout__enquiry_form__date_clear_button" />}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
