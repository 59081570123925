import { useMemo } from 'react';
import usePlatformConfig from '../../../../common/react/usePlatformConfig';
import IPrice from '../../model/IPrice';

const usePrice = (prices: IPrice[]) => {
  const {
    platformConfig: { priceDisplay },
  } = usePlatformConfig();
  const defaultPrice = {
    money: {
      amount: 0,
      currency: '',
    },
    type: '',
  };

  const { primaryPrice = defaultPrice, secondaryPrice = defaultPrice } = useMemo(() => {
    const { primaryPrice, secondaryPrice } = prices?.reduce<{ primaryPrice?: IPrice; secondaryPrice?: IPrice }>(
      (acc, price) => {
        const isPrimaryPrice = price.money.currency === priceDisplay.primary.currency;
        const isSecondaryPrice =
          priceDisplay?.secondary?.currency && price.money.currency === priceDisplay.secondary.currency;

        return {
          ...acc,
          ...(isPrimaryPrice && { primaryPrice: price }),
          ...(isSecondaryPrice && { secondaryPrice: price }),
        };
      },
      {},
    );
    return { primaryPrice, secondaryPrice };
  }, [prices, priceDisplay]);

  return { primaryPrice, secondaryPrice };
};

export default usePrice;
