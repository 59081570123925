import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import navigationStepperStyles from './navigationStepperStyles';
import { Step, StepConnector, StepLabel, Stepper, StepButton, useTheme, Typography } from '@material-ui/core';
import useNavigationSteps from '../../../hooks/useNavigationSteps';
import Translation from '../../../../../../common/components/presentation/Translation/Translation';
import { useHistory } from 'react-router';
import MainHeaderWithBackLink from '../../../../../../common/components/presentation/MainHeader/MainHeaderWithBackLink';
import collectedFilters from '../../../../../../common/service/collectedFilters';
import * as queryString from 'query-string';
import { NavigationStepLabels } from '../../../enum/NavigationStepLabels';
import { ICheckoutTheme } from 'src/common/theme/ICheckoutTheme';
import useGetDeal from '../../../../../common/hooks/queries/useGetDeal';
import { NameplateOEMCodes } from '../../../../../../common/enum/NameplateOEMCodes';
import usePlatformConfig from '../../../../../../common/react/usePlatformConfig';

const NavigationStepper: FunctionComponent = () => {
  const history = useHistory();
  const styles = navigationStepperStyles();
  const { steps, activeStep } = useNavigationSteps();
  const {
    filters: { token, expressionsOfInterest },
  } = collectedFilters();

  const searchParams = queryString.stringify({ token, expressionsOfInterest }, { arrayFormat: 'comma' });
  const confirmationStep = useMemo(() => steps.findIndex(step => step.label === NavigationStepLabels.CONFIRMATION), [
    steps,
  ]);
  const {
    checkoutLite: {
      showSubtitle,
      common: { activateHeaderTitlePerPage, showCustomHeaderTitlePerOemCode },
      confirmation: { delayConfirmationPage },
    },
  } = useTheme<ICheckoutTheme>();
  const {
    platformConfig: { fetchBreakdownJsonSchema },
  } = usePlatformConfig();

  const { data } = useGetDeal(token, fetchBreakdownJsonSchema ?? true);
  const oemCode = useMemo(() => data?.deal.car.nameplate.attributes.oemcode, [data]);
  const [hideNavigation, setHideNavigation] = useState<boolean>(!!delayConfirmationPage);

  const headerTitle = useCallback(() => {
    if (!!activateHeaderTitlePerPage) {
      return activeStep.translationKey;
    }
    if (!!showCustomHeaderTitlePerOemCode) {
      return oemCode === NameplateOEMCodes.PASSENGER
        ? 'trans__checkout__confirmation_page__title_enquiry_journey_vehicleType2'
        : 'trans__checkout__confirmation_page__title_enquiry_journey';
    }
    return 'trans__checkout__general__title_enquiry_journey';
  }, [data, activeStep.translationKey]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    if (activeStep.index !== confirmationStep) {
      setHideNavigation(false);
    } else {
      timeout = setTimeout(() => {
        setHideNavigation(false);
      }, 1500);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  if (hideNavigation) {
    return null;
  }

  return (
    <>
      <MainHeaderWithBackLink
        titleTranslation={headerTitle()}
        withBackLink={activeStep.index !== confirmationStep}
        stickyHeader
      />
      {showSubtitle && (
        <Typography variant="body2" className={styles.subHeaderText}>
          <Translation id="trans__checkout__general__subtitle_enquiry_journey" />
        </Typography>
      )}
      <Stepper
        activeStep={activeStep.index}
        className={styles.stepper}
        connector={
          <StepConnector
            classes={{
              line: styles.stepConnector,
              completed: styles.stepConnectorCompleted,
              active: styles.stepConnectorCompleted,
            }}
          />
        }
        data-test="container:navigation:stepper"
      >
        {steps.map(({ label, pathname }, index) => {
          const stepProps: { completed?: boolean } = {};
          const isLastStep = activeStep.index === steps.length - 1;

          if (isLastStep) {
            stepProps.completed = true;
          }

          const stepLabel = (
            <StepLabel
              classes={{
                label: styles.stepLabel,
                active: styles.active,
                completed: styles.completed,
                iconContainer: styles.iconContainer,
                root: styles.root,
              }}
              StepIconProps={{ classes: { text: styles.stepIconText } }}
              data-test={`navigation:step:${label}`}
              data-test-active={index === activeStep.index}
              data-test-completed={index < activeStep.index || isLastStep}
            >
              <Translation id={`trans__checkout__navigation__${label}`} />
            </StepLabel>
          );

          return (
            <Step key={label} {...stepProps}>
              {index < activeStep.index ? (
                <StepButton
                  className={styles.stepButton}
                  onClick={() => history.push({ pathname, search: searchParams })}
                >
                  {stepLabel}
                </StepButton>
              ) : (
                stepLabel
              )}
            </Step>
          );
        })}
      </Stepper>
    </>
  );
};

export default NavigationStepper;
