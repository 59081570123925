import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { default as stockApiPingQuery } from '../../graphql/queries/stockApiPing';
import useErrorHandler from '../useErrorHandler';
import usePlatformConfig from '../../../../common/react/usePlatformConfig';

const useStockApiPing = (): { isOrderPlacementDisabled: boolean } => {
  const {
    platformConfig: {
      platformFeatures: { hourDisableOrderNowFromTo },
    },
  } = usePlatformConfig();
  const [isTimeDisabled, setIsTimeDisabled] = useState(false);
  const [isPingAvailable, setIsPingAvailable] = useState(false);

  if (hourDisableOrderNowFromTo?.length) {
    const { data, error } = useQuery(stockApiPingQuery.GET_STOCKAPIPING, {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

    useErrorHandler(error);

    useEffect(() => {
      setIsPingAvailable(data?.stockApiPing);
      const currentHours = new Date().getHours();
      const [startHour, endHour] = hourDisableOrderNowFromTo;
      const isRangeInvalid = endHour - startHour < 0;
      const isAfterOrAtStart = currentHours >= startHour;
      const isBeforeEnd = currentHours < endHour;
      const timeDisabled = isRangeInvalid ? isAfterOrAtStart || isBeforeEnd : isAfterOrAtStart && isBeforeEnd;

      setIsTimeDisabled(timeDisabled);
    }, [data]);
  }

  const isOrderPlacementDisabled = hourDisableOrderNowFromTo && (isTimeDisabled || !isPingAvailable);

  return { isOrderPlacementDisabled };
};

export default useStockApiPing;
