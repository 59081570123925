import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../common/theme/ICheckoutTheme';

export type CustomerInformationSummaryClasses = 'item';

const CustomerInformationSummaryStyles = makeStyles<ICheckoutTheme, CustomerInformationSummaryClasses>(
  () => ({
    item: {},
  }),
  { name: 'Checkout4_CustomerInformationSummary' },
);

export default CustomerInformationSummaryStyles;
