import React from 'react';
import ReactDOM from 'react-dom';
import { CssResetComponent } from '@inchcape-dxp//context-hard-reset.css/react-component';
import { plainConfigValidator } from './common/service/plainConfigurationValidator';
import widgetFacadeFactory from './common/service/widgetFacadeFactory';
import WIDGET_CONFIG_SCHEMA from './configSchema.json';
import WidgetContext from './common/react/WidgetContext';
import CheckoutLiteWidget from './common/components/CheckoutLiteWidget';
import './common/assets/checkout_lite.css';
import IframeIosMobileFix from './common/components/IframeIosMobileFixComponent';
import locale from './common/service/locale';
import errorHandler from './common/service/errorHandler';
import InitErrorMessage from './common/components/presentation/Error/InitErrorMessage';
import GeneralErrorBoundary from './common/components/presentation/Error/GeneralErrorBoundary';
import DEFAULT_WIDGET_CONFIG from './common/constants/defaultWidgetConfig';
import deepmerge from 'deepmerge';
import IWidgetConfiguration from '../../common/model/IWidgetConfiguration';
import EventContainer from '../../common/event/EventContainer';

export const widgetLibrary = {
  createWidget: (configuration: IWidgetConfiguration): Promise<any> => {
    return new Promise((resolve: (facade: any) => any) => {
      configuration = deepmerge(DEFAULT_WIDGET_CONFIG, configuration);

      const localeCode = locale.getLocaleCode(configuration.dimensions?.language, configuration.dimensions?.country);
      if (localeCode) {
        configuration = Object.assign(configuration, { locale: localeCode });
      }

      const configValid: true | any = plainConfigValidator.validate(configuration, WIDGET_CONFIG_SCHEMA);
      if (configValid !== true) {
        throw configValid;
      }

      const eventContainer = new EventContainer();
      const facade = widgetFacadeFactory.create(configuration, eventContainer);

      ReactDOM.render(
        <CssResetComponent className="clt">
          <GeneralErrorBoundary>
            <IframeIosMobileFix />
            <WidgetContext.Provider value={[configuration, eventContainer]}>
              <CheckoutLiteWidget />
            </WidgetContext.Provider>
          </GeneralErrorBoundary>
        </CssResetComponent>,
        document.getElementById(configuration.containerId),
        () => resolve(facade),
      );
    }).catch(err => {
      ReactDOM.render(
        <CssResetComponent className="clt">
          <IframeIosMobileFix />
          <InitErrorMessage error={err} message={'Checkout Lite: Widget orchestration failed.'} />
        </CssResetComponent>,
        document.getElementById(configuration.containerId),
      );

      errorHandler.handleError(err);
      throw err;
    });
  },
};

export const CheckoutWidget = Object.freeze(widgetLibrary);
