import { gql } from '@apollo/client/core';

const inStorePaymentForm = {
  GET_PAYMENT_FORM_SCHEMA: gql`
    query LoadPaymentForm {
      salesEnablement {
        paymentForm {
          schema
          uiSchema
          formData
        }
      }
    }
  `,
};

export default inStorePaymentForm;
