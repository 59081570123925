export enum JourneyStepsRoutes {
  NEXT_STEPS = '/next-steps',
  YOUR_DETAILS = '/your-details',
  ACCESORIES = '/accesories',
  HEADLESS_FINANCE = '/headless-finance',
  FINANCE_INSURANCE = '/finance-insurance',
  FINANCE = '/finance',
  PAYMENT = '/payment',
  CONFIRMATION = '/confirmation',
  IDENTITY_VERIFICATION = '/identity-verification',
  DEALER = '/dealer',
  EXTRAS = '/extras',
}
