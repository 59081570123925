const hashUtilities = {
  hashSHA256: async (input: string) => {
    // crypto.subtle is undefined when the server that runs the script doesn't run with https
    if (!crypto.subtle) {
      return input;
    }
    const encoder = new TextEncoder();
    const data = encoder.encode(input);

    const hashedArrayBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashedArray = new Uint8Array(hashedArrayBuffer);
    const hashedString = Array.from(hashedArray)
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('');

    return hashedString;
  },
};

export default hashUtilities;
