import { GetDeal_deal } from '../graphql/queries/__generated__/GetDeal';
import { DxpDataLayerEventNames, DxpDataLayerType } from '../../../../../common/utilities/customDatalayerInterface';
import { NavigationStepLabels } from '../../checkout3/common/enum/NavigationStepLabels';
import { JourneyStepsRoutes } from '../../checkout4/enum/JourneyStepsRoutes';
import { CustomDxpWindow } from '../../../../../common/utilities/custom.window';
import dxpDataLayerFeatureService from '../../../../../common/utilities/dxpDataLayerFeatureService';

export interface CommonStepProps {
  label?: NavigationStepLabels;
  pathname: string;
}

declare let window: CustomDxpWindow;

export const formatDataLayerDeal = (deal: GetDeal_deal): Partial<DxpDataLayerType> => {
  if (!deal) {
    return null;
  }

  const { model, engine, specPack, exterior, interior, bodyStyle, aggregatedOptions, selectedFeatures } = deal?.car;

  return ({
    model: model?.attributes?.shortname,
    motor: engine?.attributes?.shortname,
    versions: specPack?.attributes?.shortname,
    exteriorColor: exterior?.shortname,
    interiorColor: interior?.shortname,
    accessories: selectedFeatures?.map(feature => feature?.shortname).toString(),
    transmission: engine?.attributes?.transmissionLabel,
    bodyType: bodyStyle?.attributes?.shortname,
    engineType: engine?.attributes?.shortname,
    // TODO - update when seatCapacity will be available
    seatCapacity: null,
    options: aggregatedOptions,
    servicePlan: dxpDataLayerFeatureService.getServicePlan(selectedFeatures),
  } as unknown) as Partial<DxpDataLayerType>;
};

export const getSaveEnquiryFormNextSteps = () => {
  const dataLayer = window.dataLayer.find(
    e => e.eventName && e.eventName === DxpDataLayerEventNames.CONFIGURATOR_ENTRY,
  );
  const steps = dataLayer?.nextSteps;
  const activeStep = steps?.indexOf(JourneyStepsRoutes.DEALER.replace('/', ''));
  return steps?.slice(activeStep + 1);
};

export const getCheckoutSteps = (steps: CommonStepProps[], activeStep: number) => ({
  stepName: steps[activeStep]?.pathname.replace('/', ''),
  nextSteps: steps.slice(activeStep + 1).map(({ pathname }) => pathname.replace('/', '')),
});
