import { useTheme } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import DefaultButton from '../../../../../common/components/presentation/Buttons/DefaultButton';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';
import backToFinanceButtonStyles from './backToFinanceButtonStyles';

type BackToFinanceButtonProps = {
  carConfigurationUrl: string;
};

const BackToFinanceButton: FunctionComponent<BackToFinanceButtonProps> = ({ carConfigurationUrl }) => {
  const styles = backToFinanceButtonStyles();
  const navigateToFinanceConfiguration = () => (window.location.href = carConfigurationUrl);

  const {
    checkoutLite: { checkout4 },
  } = useTheme<ICheckoutTheme>();

  return (
    <DefaultButton
      onClick={navigateToFinanceConfiguration}
      className={[styles.buttonWrapper]}
      variant="outlined"
      dataTest={'checkout:finance_section:button'}
      disableTouchRipple={!!checkout4?.disableTouchRipple}
    >
      <Translation id={'trans__checkout__extras_page__finance_back_cta'} className={styles.buttonLabel} />
    </DefaultButton>
  );
};

export default BackToFinanceButton;
