import { useReducer } from 'react';

const useForceUpdate = () => {
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  /**
   * Calls a force update function whenever the formData state changes.
   * This is needed because transformErrors callback is called before the latest formData state is updated,
   * so a force update is required to ensure that the latest state is used.
   */

  return { forceUpdate };
};

export default useForceUpdate;
