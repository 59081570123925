import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type ObjectFieldTemplateClasses = 'root' | 'child';

const objectFieldTemplateStyles = makeStyles<ICheckoutTheme, ObjectFieldTemplateClasses>(
  () => ({
    root: {
      flexDirection: 'column',
    },
    child: {
      marginBottom: 20,
    },
  }),
  { name: 'GqlCheckout_InStorePaymentForm_ObjectFieldTemplate' },
);

export default objectFieldTemplateStyles;
