import React, { FunctionComponent, useCallback, useMemo, ChangeEvent } from 'react';
import { ISchemaFieldProps } from '../../model/IJsonSchema';
import GenericInput from '../../../../common/components/genericInput/GenericInput';

export const InputField: FunctionComponent<ISchemaFieldProps> = ({ formData }) => {
  const {
    label,
    required,
    id,
    onChange,
    value,
    rawErrors,
    options: { inputLabel },
    placeholder,
    schema: { readOnly },
    disabled,
  } = formData;
  const currentError = useMemo(() => rawErrors?.length > 0 && rawErrors[0], [rawErrors]);

  const handleChange = useCallback(
    (e: ChangeEvent<{ value: string }>) => {
      onChange(e.target.value);
    },
    [value],
  );

  return (
    <GenericInput
      label={label}
      required={required}
      id={id}
      value={value}
      currentError={currentError}
      onChange={handleChange}
      inputLabel={inputLabel}
      placeholder={placeholder}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
};

export default InputField;
