import { FeaturesType, InteriorExteriorType } from '../../../../../../common/utilities/customDatalayerInterface';
import { GetDeal_deal } from 'src/gql/common/graphql/queries/__generated__/GetDeal';
import { priceExtractionHandler } from '../../../../../../common/service/dataLayerPriceDisplay';
import IDimensions from '../../../../../../common/model/IDimensions';
import { SelectedSpecPackListType } from '../../../../../../common/utilities/customDatalayerInterface';

interface BuildSelectedSpecDataArgs {
  deal: GetDeal_deal;
  dimensions: IDimensions;
}

const buildSelectedSpecData = ({ deal, dimensions }: BuildSelectedSpecDataArgs): SelectedSpecPackListType[] => {
  try {
    const keyFeatures: FeaturesType[] = [];
    const standardFeatures: FeaturesType[] = [];

    const {
      car: customizeCarData,
      car: {
        prices,
        engine: { attributes: engineAttributes },
        bodyStyle: { attributes: bodyStyleDetail },
        prices: { carPrice: carPriceDetail },
        exterior,
        interior,
        specPack,
        aggregatedOptions,
        model,
        code: carCode,
        displayName,
        nameplate: { attributes: nameplateAttributes },
      },
    } = deal;

    customizeCarData?.standardFeatures?.forEach(item => {
      standardFeatures.push({
        id: item.id,
        name: item.shortname,
        code: item.code,
        category: item.categoryCode,
      });
    });

    aggregatedOptions?.split('+').forEach((code: string) => {
      const item = customizeCarData?.selectedFeatures.find(feature => feature.code === code);
      if (item) {
        const { money } = item.prices?.[0] || {};

        keyFeatures.push({
          id: null,
          name: item.shortname,
          code: item.code,
          category: item.categoryCode,
          price: {
            totalPrice: money?.amount,
            currency: money?.currency,
          },
        });
      }
    });

    //adding colors to keyFeatures
    keyFeatures.push(
      {
        id: null,
        name: exterior.shortname,
        code: exterior.code,
        category: exterior.categoryCode,
        price: {
          totalPrice: exterior.price?.money.amount,
          currency: exterior.price?.money.currency,
        },
      },
      {
        id: null,
        name: interior.shortname,
        code: interior.code,
        category: interior.categoryCode,
        price: {
          totalPrice: interior.price?.money.amount,
          currency: interior.price?.money.currency,
        },
      },
    );

    const exteriorData: InteriorExteriorType = {
      colour: exterior.shortname,
      code: exterior.code,
      price: {
        totalPrice: exterior.price?.money.amount,
        currency: exterior.price?.money.currency,
      },
    };

    const interiorData: InteriorExteriorType = {
      colour: interior.shortname,
      code: interior.code,
      price: {
        totalPrice: interior.price?.money.amount,
        currency: interior.price?.money.currency,
      },
    };

    const discounts = carPriceDetail?.offers?.discounts?.[0];
    const originalPriceWithoutTaxExtraKeys = carPriceDetail?.originalPrice.map(item => ({
      ...item,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      taxes: item.taxes.map(({ label, aggregated, ...rest }) => rest),
    }));
    const finalPriceWithoutTaxExtraKeys = carPriceDetail?.finalPrice.map(item => ({
      ...item,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      taxes: item.taxes.map(({ label, aggregated, ...rest }) => rest),
    }));
    const originalPrice = priceExtractionHandler(originalPriceWithoutTaxExtraKeys, dimensions).primaryPrice;
    const finalPrice = priceExtractionHandler(finalPriceWithoutTaxExtraKeys, dimensions).primaryPrice;
    return [
      {
        nameplate: {
          code: carCode,
          name: displayName,
          modelYear: model?.attributes?.modelyear,
          brand: nameplateAttributes.brand,
          description: model?.attributes?.longdescription,
        },
        specPack: {
          code: specPack?.attributes?.nviccode,
          name: specPack?.attributes?.shortname,
        },
        engine: {
          description: engineAttributes?.longdescription,
          fuelType: engineAttributes?.fuelType,
          fuelLabel: engineAttributes?.fuelLabel,
          powerTrain: engineAttributes?.powertrain,
          capacity: engineAttributes?.capacity,
          maximumPower: engineAttributes?.maximumpower,
          enginePower: engineAttributes?.enginepower,
          maximumTorque: engineAttributes?.maximumtorque,
          transmission: engineAttributes?.transmissionLabel,
          transmissionType: engineAttributes?.transmissionType,
        },
        exterior: exteriorData,
        interior: interiorData,
        bodyType: {
          bodyStyleType: bodyStyleDetail?.bodyStyleType,
          description: bodyStyleDetail?.longdescription,
          code: bodyStyleDetail?.oemcode,
          price: {
            totalPrice: null,
            currency: null,
          },
        },
        standardFeatures: standardFeatures,
        addOnFeatures: keyFeatures,
        prices: [
          {
            carPrice: {
              originalPrice: originalPrice,
              totalDiscount: carPriceDetail?.totalDiscount?.[0]?.amount,
              finalPrice: finalPrice,
              offers: [{ amount: discounts?.value[0]?.amount }],
            },
            totalFeatures: null,
            totalConfiguration: null,
            totalPrice: prices?.totalPrice[0]?.money?.amount,
            currency: prices?.totalPrice[0]?.money?.currency,
            taxes: [
              {
                amount: prices?.carPrice?.finalPrice[1]?.taxes[0]?.money?.amount,
                type: prices?.carPrice?.finalPrice[1]?.taxes[0]?.type,
              },
            ],
          },
        ],
      },
    ];
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('DataLayer error', err);
  }
};

export default buildSelectedSpecData;
