import { gql } from '@apollo/client/core';
import money from '../money';
import residualAmount from '../residualAmount';

const dealFinance = {
  DEAL_FINANCE: gql`
    ${money.MONEY}
    ${residualAmount.RESIDUAL_AMOUNT}
    fragment DealFinance on DealFinance {
      type
      formInput {
        paymentType
        financeProductId
        term
        deposit
        interestRate
        mileage
      }
      simulation {
        oneForOne
        amountFinanced {
          currency
          amount
        }
        interestRate
        comparisonRate
        frequency
        paymentValue {
          currency
          amount
        }
        loanTermPeriod {
          value
          unit
        }
        attributes {
          ...ResidualAmount
          depositAmountPercentage
          balloonAmountPercentage
          residualAmountPercentage
          upfrontFees {
            amount
            currency
          }
          calculatorNetPrice
        }
        frequency
        deposit {
          ...Money
        }
        amountPayable {
          ...Money
        }
        totalAmountPayable {
          ...Money
        }
        financeProductName
      }
      breakdown @include(if: $fetchBreakdownJsonSchema) {
        schema
      }
      breakdownBlocks {
        blocks {
          title
          value
          shouldBeFormatted
          highlighted
          isNegative
          key
        }
        currency
      }
      disclaimers {
        purpose
        value
      }
    }
  `,
};
export default dealFinance;
