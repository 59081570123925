import { useCallback, useState } from 'react';
import { AjvError, IInstorePaymentForm } from '../model/IJsonSchema';
import { FormDataType } from './useCustomErrorMessagesMapper';
import { useIntl } from 'react-intl';
import inStorePaymentFormErrorTransformer from '../service/inStorePaymentFormErrorTransformer';

const flattenFormData = (formData: FormDataType): Record<string, string | number | boolean | null | undefined> => {
  if (!formData) {
    return {};
  }

  return Object.entries(formData).reduce(
    (prevValue, [key, value]) => ({
      ...prevValue,
      ...(typeof value === 'object'
        ? {
            ...flattenFormData(value),
          }
        : {
            [key]: value,
          }),
    }),
    {},
  );
};

const useInStorePaymentFormValidate = () => {
  const intl = useIntl();

  const [hasErrors, setHasErrors] = useState<boolean>(true);
  const [asyncErrors, setAsyncErrors] = useState<AjvError[] | null>(null);

  const checkEmptyFields = (requiredFields: string[], formData: FormDataType) => {
    const flattenedFormData = flattenFormData(formData);

    return requiredFields.some(requiredField => !flattenedFormData[requiredField]);
  };

  const handleValidationErrors = useCallback(
    (requiredFields: string[], data: IInstorePaymentForm) => {
      const hasValidationErrors = data.errors?.length > 0;

      setHasErrors(checkEmptyFields(requiredFields, data.formData) || hasValidationErrors);
    },
    [hasErrors, asyncErrors],
  );

  const handleAsyncValidationErrors = useCallback(
    errors => {
      const err: AjvError[] = inStorePaymentFormErrorTransformer.from(errors, intl);
      setAsyncErrors(err);
    },
    [intl],
  );

  const resetAsyncErrors = useCallback(() => {
    setAsyncErrors(null);
  }, []);

  return { handleValidationErrors, hasErrors, asyncErrors, handleAsyncValidationErrors, resetAsyncErrors };
};

export default useInStorePaymentFormValidate;
