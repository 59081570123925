import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type DealerSummaryClasses = 'dealerSummary' | 'dealerName' | 'dealerAddress' | 'dealerItem';

const dealerSummaryStyles = makeStyles<ICheckoutTheme, DealerSummaryClasses>(
  ({ palette }) => ({
    dealerSummary: {},
    dealerName: {
      textTransform: 'uppercase',
      color: palette.common.black,
    },
    dealerAddress: {},
    dealerItem: {},
  }),
  { name: 'Checkout4_DealerSummary' },
);

export default dealerSummaryStyles;
