import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import { GetDeal_deal } from '../../../../../common/graphql/queries/__generated__/GetDeal';
import mercadoPagoContainerStyles from './mercadoPagoContainerStyles';
import FailedPaymentError from '../../ErrorPages/FailedPaymentError';
import MercadoPago from './MercadoPago';
import { useMercadoPago } from '../../../hooks/useMercadoPago';

export interface IMercadoPagoProps {
  goToNextStep: () => void;
  deal: GetDeal_deal;
  loading?: boolean;
}

const MercadoPagoContainer: FunctionComponent<IMercadoPagoProps> = ({ goToNextStep, deal, loading }) => {
  const styles = mercadoPagoContainerStyles();
  const { isFailed, paymentId, getIframeUrl } = useMercadoPago(goToNextStep, deal, loading);

  return (
    <>
      {isFailed ? (
        <FailedPaymentError />
      ) : (
        <>
          {!paymentId && <MercadoPago />}
          <Box data-test="payment:mercadopago:iframe">
            <iframe src={getIframeUrl()} className={styles.container} />
          </Box>
        </>
      )}
    </>
  );
};

export default MercadoPagoContainer;
