import React, { FunctionComponent, useCallback } from 'react';
import { Container } from '@material-ui/core';
import { useHistory } from 'react-router';
import Payment from '../../../commonSteps/payment/components/Payment';
import { GetDeal_deal } from '../../../common/graphql/queries/__generated__/GetDeal';
import { JourneyStepsRoutes } from '../../enum/JourneyStepsRoutes';
import { UrlParamTypes } from '../../../../common/enum/UrlParamTypes';
import useUrlDealId from '../../../common/hooks/useUrlDealId';
import paymentPageStyles from './paymentPageStyles';

export interface IPaymentPage {
  deal?: GetDeal_deal;
}

const PaymentPage: FunctionComponent<IPaymentPage> = ({ deal }) => {
  const history = useHistory();
  const token = useUrlDealId();
  const styles = paymentPageStyles();
  const goToNextStep = useCallback(() => {
    history.replace({
      pathname: JourneyStepsRoutes.CONFIRMATION,
      search: `?${UrlParamTypes.TOKEN}=${token}`,
    });
  }, []);

  return (
    <Container data-test={'container:checkout:payment'} className={styles.container}>
      <Payment goToNextStep={goToNextStep} deal={deal} />
    </Container>
  );
};

export default PaymentPage;
