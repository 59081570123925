export enum UrlParamTypes {
  TOKEN = 'token',
  EXPRESSIONS_OF_INTEREST = 'expressionsOfInterest',
  FROM = 'from',
  TARGET = 'target',
  REVERT_TO_BYO = 'revertToByo',
  NO_MODAL = 'noModal',
  PAYMENT_ID = 'paymentId',
  NEW_BASKET = 'newBasket',
}
