import { createContext } from 'react';

type SalesEnablementContextType = {
  isInSalesEnablement: boolean;
  sessionId: string | null;
  sendIsInCheckoutPage?: (data: boolean) => void;
  dealVersion: number;
  isDealExported: boolean;
};

const SalesEnablementContext = createContext<SalesEnablementContextType>({
  isInSalesEnablement: false,
  sessionId: null,
  dealVersion: 0,
  isDealExported: false,
});

export default SalesEnablementContext;
