import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { axiosSetCookieInterceptor, createPostMessageTokenHook } from '@inchcape-dxp/automotive-axios-extensions';
import urlRoute from '../service/urlRoute';
import CONSTANTS from '../constants/constants';
import { SESSION } from '../constants/session';
import IDimensions from '../model/IDimensions';
import deepEqual from 'fast-deep-equal';

const { BASE_URL, BE_DEFAULT_DIMENSIONS } = CONSTANTS;

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

let registeredInterceptors: number[] = [];

const httpClient = {
  configure(cookieDomain: string, dimensions: IDimensions): void {
    registeredInterceptors.forEach(id => axiosInstance.interceptors.request.eject(id));
    registeredInterceptors = [];

    httpClient.configureSessionPersistence(cookieDomain);
    httpClient.configureMultiDimensionQuery(dimensions);
  },

  configureSessionPersistence(cookieDomain: string): void {
    axiosSetCookieInterceptor(
      axiosInstance,
      {
        cookieDomain: cookieDomain || SESSION.COOKIE_DOMAIN,
        cookieName: SESSION.COOKIE_NAME,
        cookieLifetime: SESSION.COOKIE_LIFETIME,
        requestHeaderName: SESSION.HEADER_NAME.toLowerCase(),
        responseHeaderName: SESSION.HEADER_NAME.toLowerCase(),
      },
      {
        onReceive: createPostMessageTokenHook(urlRoute.getBaseUrl(CONSTANTS.PLATFORM_API_URL)),
      },
    );
  },

  configureMultiDimensionQuery(dimensions: IDimensions): void {
    if (deepEqual(dimensions, BE_DEFAULT_DIMENSIONS)) {
      return;
    }

    registeredInterceptors.push(
      axiosInstance.interceptors.request.use(config => {
        config.params = {
          ...dimensions,
          ...config.params,
        };
        return config;
      }),
    );
  },

  get<T extends any>(url: string, params?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return axiosInstance.get(url, { params, ...config }).catch(this.handleError);
  },

  post<T extends any>(url: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return axiosInstance.post(url, data, config).catch(this.handleError);
  },

  patch<T extends any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return axiosInstance.patch(url, data, config).catch(this.handleError);
  },

  handleError(error: AxiosError): Promise<AxiosResponse> {
    throw error;
  },
};

export default httpClient;
