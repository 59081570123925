import { gql } from '@apollo/client/core';

const dealers = {
  GET_DEALERS: gql`
    query GetDealers($postCode: String, $retailGroup: String) {
      dealers(postCode: $postCode, retailGroup: $retailGroup) {
        count
        nodes {
          id
          externalId
          label
          postCode
          longitude
          latitude
          address {
            name
            address
            city
            geoLevel
            phone
          }
          attributes {
            paymentMerchantId
          }
        }
        defaultMapLocation {
          longitude
          latitude
        }
      }
    }
  `,
};

export default dealers;
