import { createContext } from 'react';

export interface ICurrencyPreferenceContextProps {
  isSecondaryCurrencyDisplay: boolean;
  setIsSecondaryCurrencyDisplay: (value: ((prevState: boolean) => boolean) | boolean) => void;
  displayedCurrency: string;
}

const CurrencyPreferenceContext = createContext<ICurrencyPreferenceContextProps>({
  isSecondaryCurrencyDisplay: false,
  setIsSecondaryCurrencyDisplay: () => null,
  displayedCurrency: '',
});

export default CurrencyPreferenceContext;
