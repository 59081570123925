import React, { FunctionComponent, useCallback, useState } from 'react';
import { Checkbox, FormControlLabel, FormControl, Box, Typography } from '@material-ui/core';
import PaymentDisclaimerModal from '../paymentDisclaimerModal/PaymentDisclaimerModal';
import paymentDisclaimerStyles from './paymentDisclaimerStyles';
import { useIntl } from 'react-intl';
import PaymentSummary from '../paymentSummary/PaymentSummary';
import ContinueButton from '../../buttons/continueButton/ContinueButton';
import PaymentTermsAndConditionsModal from '../paymentTermsAndConditions/PaymentTermsAndConditionsModal';
import usePlatformConfig from '../../../../../common/react/usePlatformConfig';

interface IPaymentDisclaimerProps {
  handleClick?: () => void;
}

const PaymentDisclaimer: FunctionComponent<IPaymentDisclaimerProps> = ({ handleClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const styles = paymentDisclaimerStyles();
  const intl = useIntl();
  const {
    platformConfig: {
      platformFeatures: { usePaymentTermsAndConditionsModal, isPaymentModalDisabled, useSubaruLink },
    },
  } = usePlatformConfig();

  const openModal = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      if (!isPaymentModalDisabled) {
        e.preventDefault();
        const element = e.target as HTMLElement;
        if (element && element.tagName === 'A') {
          setIsModalOpen(true);
        }
      }
    },
    [isPaymentModalDisabled],
  );

  return (
    <Box className={styles.container} data-test="checkout:payment_disclaimer">
      <PaymentSummary />
      <FormControl fullWidth>
        <FormControlLabel
          className={styles.controlContainer}
          control={
            <Box className={styles.controlCheckbox}>
              <Checkbox
                className={styles.icon}
                color="primary"
                checked={checked}
                onChange={e => setChecked(e.target.checked)}
                data-test="online_order:payment_disclaimer:checkbox"
              />
            </Box>
          }
          label={
            <Typography
              data-test="online_order:payment_disclaimer:label"
              onClick={!useSubaruLink ? openModal : undefined}
              className={styles.paymentDisclaimerLabel}
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'trans__checkout__next_step__payment_page_disclaimer',
                }),
              }}
            />
          }
        />
      </FormControl>
      <Box className={styles.buttonWrapper}>
        <ContinueButton
          testId="checkout:continue_button:payment_disclaimer"
          activateNextStep={false}
          disabled={!checked}
          handleClick={handleClick}
          className={styles.continueButton}
        />
      </Box>
      {!isPaymentModalDisabled && usePaymentTermsAndConditionsModal ? (
        <PaymentTermsAndConditionsModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} setIsAccepted={setChecked} />
      ) : (
        <PaymentDisclaimerModal isOpen={isModalOpen} toggleModal={setIsModalOpen} />
      )}
    </Box>
  );
};

export default PaymentDisclaimer;
