import React, { FunctionComponent, useEffect, useState } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import errorMessageHoc from '../../../common/components/presentation/Error/errorMessageHoc';
import checkout4LiteMainRoutesContainerStyles from './checkout4LiteMainRoutesContainerStyles';
import useWidgetContext from '../../../common/react/useWidgetContext';
import useCheckoutContext from '../../common/hooks/useCheckoutContext';
import useAzureMonitor from '../../../common/hooks/useAzureMonitor';
import { createBrowserHistory } from 'history';
import { WidgetEvents } from '../../../common/event/WidgetEvents';
import { Routes } from '../../../common/enum/Routes';
import { JourneyStepsRoutes } from '../enum/JourneyStepsRoutes';
import Navigation from './navigation/Navigation';
import { Box } from '@material-ui/core';
import Checkout4Redirects from './Checkout4Redirects';
import GqlDeferredCheckout from '../index';
import CheckoutConfirmation from './confirmation/CheckoutConfirmation';
import DealUnavailablePage from '../../../common/components/presentation/ExceptionPages/DealUnavailablePage';
import AccessDeniedPage from '../../../common/components/presentation/ExceptionPages/AccessDeniedPage';
import NotFoundPage from '../../../common/components/presentation/ExceptionPages/NotFoundPage';

const Checkout4LiteRouter: FunctionComponent = () => {
  const styles = checkout4LiteMainRoutesContainerStyles();
  const { eventContainer, configuration } = useWidgetContext();
  const { error: gqlDealError } = useCheckoutContext();
  useAzureMonitor();

  const [history] = useState(createBrowserHistory({ basename: configuration.basePath }));

  useEffect(() => {
    eventContainer.emit(WidgetEvents.INITIALIZED);
  }, []);

  useEffect(() => {
    if (gqlDealError) {
      history.replace(Routes.DEAL_UNAVAILABLE, {});
    }
  }, [gqlDealError]);

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact={true}
          path={[
            JourneyStepsRoutes.NEXT_STEPS,
            JourneyStepsRoutes.YOUR_DETAILS,
            JourneyStepsRoutes.FINANCE_INSURANCE,
            JourneyStepsRoutes.PAYMENT,
            JourneyStepsRoutes.IDENTITY_VERIFICATION,
            JourneyStepsRoutes.CONFIRMATION,
            JourneyStepsRoutes.DEALER,
            JourneyStepsRoutes.EXTRAS,
            JourneyStepsRoutes.FINANCE,
          ]}
          component={Navigation}
        />
      </Switch>
      {/*dxp-id - added for widget integration*/}
      <Box id="dxp-checkout-content" className={styles.root}>
        <Checkout4Redirects />
        <Switch>
          <Route
            exact={true}
            path={[
              JourneyStepsRoutes.NEXT_STEPS,
              JourneyStepsRoutes.ACCESORIES,
              JourneyStepsRoutes.HEADLESS_FINANCE,
              JourneyStepsRoutes.FINANCE,
              JourneyStepsRoutes.EXTRAS,
              JourneyStepsRoutes.YOUR_DETAILS,
              JourneyStepsRoutes.FINANCE_INSURANCE,
              JourneyStepsRoutes.PAYMENT,
              JourneyStepsRoutes.IDENTITY_VERIFICATION,
              JourneyStepsRoutes.DEALER,
            ]}
            component={GqlDeferredCheckout}
          />
          <Route exact={true} path={JourneyStepsRoutes.CONFIRMATION} component={CheckoutConfirmation} />
          <Route exact={true} path={Routes.DEAL_UNAVAILABLE} component={DealUnavailablePage} />
          <Route exact={true} path={Routes.ACCESS_DENIED} component={AccessDeniedPage} />
          <Redirect from={Routes.INDEX} to={`${Routes.NEXT_STEPS}${history.location.search}`} />
          <Route component={NotFoundPage} />
        </Switch>
      </Box>
    </Router>
  );
};

export default errorMessageHoc(Checkout4LiteRouter);
