import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MinimalLoader from '../../../common/components/presentation/MinimalLoader/MinimalLoader';
import useWidgetContext from '../../../common/react/useWidgetContext';
import { assetManager } from '../../../../../common/service/assetManager';
import CONSTANTS from '../../../common/constants/constants';
import FC_CONFIG from '../../../common/constants/fcConfig';
import { ViewType } from '../../../gql/checkout4/enum/ViewModeType';
import errorHandler from '../../../common/service/errorHandler';

interface IFinanceCalculatorWidget {
  carConfiguration: string;
  postcode?: string;
}

const FinanceCalculatorWidget: FunctionComponent<IFinanceCalculatorWidget> = ({ carConfiguration, postcode }) => {
  const widgetId = useMemo(() => 'dxp-finance-calculator-container' + Math.floor(Math.random() * 100), []);
  const widgetInstance: any = useRef(null);
  const fcLibrary: any = useRef(null);
  const {
    configuration: { dimensions },
  } = useWidgetContext();
  const [isLoading, setLoadingStatus] = useState(false);

  const ensureLibraryLoaded = () => {
    const widgetUrl: string = FC_CONFIG.dxpWidgetUrl;

    if (assetManager.isScriptLoaded(widgetUrl)) {
      if (!fcLibrary.current && (window as any).DXPFinanceCalculator) {
        fcLibrary.current = (window as any).DXPFinanceCalculator;
      }

      return Promise.resolve();
    }

    return Promise.resolve()
      .then(() => {
        // keep in mind there's a slight difference between the react versions of dxp and auto-widgets
        if (!assetManager.isScriptLoaded(CONSTANTS.REACT_DEPENDENCIES.react18)) {
          return assetManager
            .loadScript(CONSTANTS.REACT_DEPENDENCIES.react18)
            .then(() => assetManager.loadScript(CONSTANTS.REACT_DEPENDENCIES.reactDom18));
        }
      })
      .then(() => assetManager.loadScript(widgetUrl, 'module'))
      .then(() => {
        fcLibrary.current = (window as any).DXPFinanceCalculator;
      });
  };

  const getLibrary = useCallback(() => {
    if (!fcLibrary.current) {
      throw new Error(`Attempted to use DXP Finance Calculator library without loading it first`);
    }

    return fcLibrary.current;
  }, [fcLibrary.current]);

  const initializeWidget = () => {
    if (widgetInstance.current) {
      widgetInstance.current.destroy();
    }

    setLoadingStatus(true);

    const widgetConfig: any = {
      containerId: widgetId,
      viewMode: ViewType.FULL,
      car: {
        code: carConfiguration,
        featureCodes: {
          values: [],
        },
      },
      postcode,
      dimensions,
      version: 'new',
    };

    const dxpWidget = getLibrary();
    dxpWidget.createFinanceCalculatorWidget(widgetConfig);
  };

  useEffect(() => {
    if (!widgetInstance.current) {
      ensureLibraryLoaded()
        .then(() => initializeWidget())
        .catch(errorHandler.promise())
        .finally(() => setLoadingStatus(false));
    }

    return () => {
      if (widgetInstance.current) {
        widgetInstance.current.destroy();
      }
    };
  }, []);

  return (
    <>
      {isLoading && <MinimalLoader />}
      {/* @Todo add test for this scenario */}
      <div id={widgetId} data-test="checkout:finance-calculator" />
    </>
  );
};

export default FinanceCalculatorWidget;
