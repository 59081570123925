import { useEffect, useState, useCallback, useMemo } from 'react';
import * as H from 'history';
import { JourneyStepsRoutes } from '../../../gql/checkout4/enum/JourneyStepsRoutes';
import { SalesEnablementEventTypes } from '../enum/SalesEnablementEventTypes';

export default function useSalesEnablementCommunication() {
  const isInIframe = window !== top || (window as any).__CYPRESS_IS_IN_IFRAME;
  const isInSalesEnablement = isInIframe;
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [dealVersion, setDealVersion] = useState(0);
  const [isDealExported, setIsDealExported] = useState(false);

  const isInConfirmationPage = (location: H.Location) => {
    return useMemo(() => location.pathname === JourneyStepsRoutes.CONFIRMATION && isInSalesEnablement, [location]);
  };

  const sendIsInCheckoutPage = useCallback((isInCheckoutPage: boolean) => {
    window.parent.postMessage(
      {
        type: SalesEnablementEventTypes.IS_CHECKOUT_PAGE,
        payload: isInCheckoutPage,
      },
      '*',
    );
  }, []);

  useEffect(() => {
    if (!isInSalesEnablement) {
      return;
    }

    const onMessage = (message: MessageEvent) => {
      if (message.data.type === SalesEnablementEventTypes.SESSION_ID) {
        setSessionId(message.data.payload);
      }
      if (message.data.type === SalesEnablementEventTypes.RELOAD_DEAL) {
        setDealVersion(state => ++state);
      }
      if (message.data.type === SalesEnablementEventTypes.IS_DEAL_EXPORTED) {
        setIsDealExported(message.data.payload);
      }
    };

    window.addEventListener('message', onMessage);
    window.parent.postMessage({ type: SalesEnablementEventTypes.REQUEST_SESSION_ID }, '*');
    return () => window.removeEventListener('message', onMessage);
  }, []);

  return {
    isInSalesEnablement,
    sessionId,
    dealVersion,
    isInConfirmationPage,
    sendIsInCheckoutPage,
    isDealExported,
  };
}
