import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type SelectPaymentAmountClasses =
  | 'container'
  | 'radioGroupContainer'
  | 'controlLabel'
  | 'reserveFeeControlLabel'
  | 'fullFeeControlLabel'
  | 'controlRadioButton'
  | 'radio'
  | 'amount'
  | 'checked';

const selectPaymentAmountStyles = makeStyles<ICheckoutTheme, SelectPaymentAmountClasses>(
  ({ palette, spacing, breakpoints }) => ({
    container: {
      position: 'relative',
    },
    radioGroupContainer: {
      alignItems: 'stretch',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingTop: spacing(5),
      paddingBottom: spacing(7),
      [breakpoints.down('xs')]: {
        flexDirection: 'column',
        width: '95%',
      },
    },
    controlLabel: {
      border: `1px solid ${palette.primaryDisabled.main}`,
      padding: '5px 10px',
      flexGrow: 2,
      justifyContent: 'space-between',
      fontWeight: 700,
      '&$checked': {
        border: `1px solid ${palette.secondary.main}`,
      },
    },
    reserveFeeControlLabel: {},
    fullFeeControlLabel: {},
    controlRadioButton: {},
    radio: {
      color: palette.primaryDisabled.main,
    },
    amount: {
      fontWeight: 300,
      fontSize: '14px',
      color: palette.primaryDisabled.main,
    },
    checked: {},
  }),
  { name: 'Checkout4_SelectPaymentAmount' },
);

export default selectPaymentAmountStyles;
