import React, { FunctionComponent } from 'react';
import Stripe from './Stripe';
import { Box, Typography } from '@material-ui/core';
import Translation from '../../../../../../common/components/presentation/Translation/Translation';
import stripeStyles from './stripeStyles';
import { useIntl } from 'react-intl';
import { GetDeal_deal } from 'src/gql/common/graphql/queries/__generated__/GetDeal';
import FailedPaymentError from '../../ErrorPages/FailedPaymentError';
import { TransactionErrorTypes } from '../../../../../../common/enum/TransactionErrorTypes';

interface IStripeContainerProps {
  deal: GetDeal_deal;
}

const StripeContainer: FunctionComponent<IStripeContainerProps> = ({ deal }) => {
  const styles = stripeStyles();
  const intl = useIntl();
  const { status } = deal;
  const isStatusFailedOrCancelled =
    status === TransactionErrorTypes.FAILED || status === TransactionErrorTypes.CANCELLED;

  return (
    <Box>
      {isStatusFailedOrCancelled ? (
        <FailedPaymentError />
      ) : (
        <>
          <Typography variant="subtitle1" className={styles.title}>
            <Translation id="trans__checkout__payment_page__subtitle" />
          </Typography>
          <Typography variant="subtitle2">
            <Translation className={styles.description} id="trans__checkout__payment_page__description1" />
          </Typography>
          <Typography variant="subtitle1" className={styles.description2}>
            <Translation
              id="trans__checkout__payment_page__description2"
              values={{
                email: (
                  <a
                    className={styles.email}
                    href={`mailto:${intl.formatMessage({
                      id: 'trans__checkout__payment_page__email',
                    })}`}
                  >
                    <Translation id={'trans__checkout__payment_page__email'} />
                  </a>
                ),
              }}
            />
          </Typography>
        </>
      )}
      <Stripe dealStatus={status} />
    </Box>
  );
};

export default StripeContainer;
