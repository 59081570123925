import { gql } from '@apollo/client/core';
import baseImage from '../baseImage';
import price from '../price';

const dealFeature = {
  DEAL_FEATURE: gql`
    ${baseImage.BASE_IMAGE}
    ${price.PRICE}

    fragment DealFeature on DealFeature {
      id
      shortname
      code
      categoryCode
      image {
        ...BaseImage
      }
      prices {
        ...Price
      }
      attributes {
        childrenDetails {
          code
          categoryCode
          id
          shortname
        }
      }
    }
  `,
};

export default dealFeature;
