import React, { FunctionComponent, useState } from 'react';
import { Box } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import reserveForm from '../../../graphql/mutations/initPayment';
import Translation from '../../../../../../common/components/presentation/Translation/Translation';
import DefaultButton from '../../../../../../common/components/presentation/Buttons/DefaultButton';
import webpayStyles from './webpayStyles';
import {
  InitializePayment,
  InitializePaymentVariables,
} from '../../../graphql/mutations/__generated__/InitializePayment';
import { paymentApiWebpay } from '../../../api/paymentApiWebpay';
import collectedFilters from '../../../../../../common/service/collectedFilters';
import Webpay from './Webpay';
import errorHandler from '../../../../../common/service/errorHandler';
import clsx from 'clsx';
import { GetDeal_deal } from '../../../../../common/graphql/queries/__generated__/GetDeal';
import useCheckoutContext from '../../../../../common/hooks/useCheckoutContext';

export interface IPaymentVisaNet {
  deal: GetDeal_deal;
}

const WebpayContainer: FunctionComponent<IPaymentVisaNet> = ({ deal }) => {
  const styles = webpayStyles();

  const { paymentType } = useCheckoutContext();

  const [initPayment, { loading }] = useMutation<InitializePayment, InitializePaymentVariables>(
    reserveForm.INITIALIZE_PAYMENT,
  );
  const [initError, setInitError] = useState(null);
  const [softLocked, setSoftLocked] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const {
    filters: { token },
  } = collectedFilters();

  const handlePayment = async () => {
    setInitError(null);
    setBtnLoading(true);

    return await initPayment({
      variables: {
        identifier: token,
        paymentType: paymentType,
      },
    })
      .then(transaction => {
        const { id, url } = transaction.data.initPayment;
        setBtnLoading(false);
        paymentApiWebpay.initializePaymentTransaction(id, url);
      })
      .catch(e => {
        const setSoftLockedError = errorHandler.handleSoftLock(e);
        setBtnLoading(false);
        setInitError(e);
        setSoftLocked(setSoftLockedError?.isStockLocked);
      });
  };

  return (
    <Box>
      <Webpay initError={initError} loading={loading} deal={deal} softLocked={softLocked} />
      <DefaultButton
        className={[clsx(styles.payButton, softLocked && styles.hidePaymentButton)]}
        dataTest="button:checkout:webpay"
        variant="contained"
        onClick={handlePayment}
        disabled={btnLoading}
      >
        <Translation id="trans__checkout__payment_page__pay_CTA" />
      </DefaultButton>
    </Box>
  );
};

export default WebpayContainer;
