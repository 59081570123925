import queryParams from './queryParams';
import { useLocation } from 'react-router';
import { UrlParamTypes } from '../enum/UrlParamTypes';
import { IUrlParams } from '../model/IUrlParams';

const collectedFilters = () => {
  const location = useLocation();
  const token = queryParams.getParam(UrlParamTypes.TOKEN, location);
  const expressionsOfInterest = queryParams.getParam(UrlParamTypes.EXPRESSIONS_OF_INTEREST, location);
  const isNewBasket = queryParams.getParam(UrlParamTypes.NEW_BASKET, location);

  const filters: IUrlParams = {
    token: token || '',
    expressionsOfInterest,
    isNewBasket,
  };

  return { filters };
};

export default collectedFilters;
