import React, { FunctionComponent, ChangeEvent, useState } from 'react';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import selectPaymentAmountStyles from './selectPaymentAmountStyles';
import clsx from 'clsx';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import useCheckoutContext from '../../../../common/hooks/useCheckoutContext';
import useTotalPrices from '../../../../common/hooks/prices/useTotalPrice';
import useCurrencyPreference from '../../../context/useCurrencyPreference';
import Price from '../../../../common/components/prices/price/Price';
import { PriceDisplayType } from '../../../../../../../common/enum/PriceDisplayType';
import useMoney from '../../../../common/hooks/prices/useMoney';
import { PaymentType } from '../../../../../__generated__/globalTypes';

interface IPaymentDisclaimerProps {
  handleClick?: () => void;
}

enum PriceSelectionValues {
  reservation = 'RESERVATION',
  full = 'FULL',
}

const SelectPaymentAmount: FunctionComponent<IPaymentDisclaimerProps> = () => {
  const styles = selectPaymentAmountStyles();
  const { deal, setPaymentType } = useCheckoutContext();
  const { isSecondaryCurrencyDisplay, displayedCurrency } = useCurrencyPreference();
  const { totalFinalPrices } = useTotalPrices(deal.car.prices.totalPrice);
  const [primaryTotalPrice, secondaryTotalPrice] = totalFinalPrices;
  const reserveFee = deal.attributes?.paymentFee?.reserveFee;
  const { primaryPrice, secondaryPrice } = useMoney(reserveFee);
  const [selectedValue, setSelectedValue] = useState<PriceSelectionValues>(PriceSelectionValues.reservation);
  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    setSelectedValue(value as PriceSelectionValues);
    if (value === PriceSelectionValues.reservation) {
      setPaymentType(PaymentType.reservation_fee);
    } else {
      setPaymentType(PaymentType.full_payment);
    }
  };

  const radioData = [
    {
      value: PriceSelectionValues.reservation,
      translationId: 'trans__checkout__next_step__payment_page__select_amount__reservation_fee_label',
      price: isSecondaryCurrencyDisplay ? secondaryPrice?.amount || 0 : primaryPrice?.amount || 0,
      dataTest: 'select_payment:reservation_fee',
    },
    {
      value: PriceSelectionValues.full,
      translationId: 'trans__checkout__next_step__payment_page__select_amount__pay_full_order_fee_label',
      price: isSecondaryCurrencyDisplay ? secondaryTotalPrice.money?.amount || 0 : primaryTotalPrice.money?.amount || 0,
      dataTest: 'select_payment:full_payment',
    },
  ];

  return (
    <Box className={styles.container} data-test="checkout:payment_disclaimer">
      <FormControl fullWidth>
        <RadioGroup className={styles.radioGroupContainer} onChange={handleRadioChange} value={selectedValue}>
          {radioData.map(item => (
            <FormControlLabel
              key={item.value}
              className={clsx(styles.controlLabel, {
                [styles.reserveFeeControlLabel]: item.value === PriceSelectionValues.reservation,
                [styles.fullFeeControlLabel]: item.value === PriceSelectionValues.full,
              })}
              data-test={item.dataTest}
              label={
                <Typography
                  variant="subtitle1"
                  className={
                    styles[
                      item.value === PriceSelectionValues.reservation ? 'reserveFeeControlLabel' : 'fullFeeControlLabel'
                    ]
                  }
                >
                  <Translation
                    id={item.translationId}
                    values={{
                      amount: (
                        <span className={styles.amount}>
                          (
                          <Price
                            price={item.price}
                            priceDisplayType={PriceDisplayType.PRIMARY}
                            currency={displayedCurrency}
                            className={styles.amount}
                            variant="h5"
                          />
                          )
                        </span>
                      ),
                    }}
                  />
                </Typography>
              }
              value={item.value}
              labelPlacement="start"
              control={
                <Radio
                  className={styles.controlRadioButton}
                  classes={{
                    root: styles.radio,
                    checked: styles.checked,
                  }}
                />
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default SelectPaymentAmount;
