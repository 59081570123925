import { useEffect, useState } from 'react';
import { PAYMENT_CONFIG } from '../../../../common/constants/payment';
import { TransactionErrorTypes } from '../../../../common/enum/TransactionErrorTypes';
import { UrlParamTypes } from '../../../../common/enum/UrlParamTypes';
import { GetDeal_deal, GetDeal_deal_paymentInfo } from '../../../common/graphql/queries/__generated__/GetDeal';

type PaymentInfoResume = {
  transactionTime: string | number;
  transactionId: string;
};

export const useMercadoPago = (goToNextStep: () => void, deal: GetDeal_deal, loading: boolean) => {
  const token = deal?.uuid;
  const [checkTransaction, setCheckTransaction] = useState<boolean>(false);
  const [getUrlResponse, setUrlResponse] = useState<Location>(null);
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const [paymentId, setPaymentId] = useState<string>(null);
  const getIframeUrl = () => {
    if (paymentId) {
      return `${PAYMENT_CONFIG.dxpWidgetUrlWeb}?${UrlParamTypes.PAYMENT_ID}=${paymentId}`;
    }
    return `${PAYMENT_CONFIG.dxpWidgetUrlWeb}?${UrlParamTypes.TOKEN}=${token}`;
  };
  const getFirstElementPaymentId = (paymentInfo: GetDeal_deal_paymentInfo[]): string => {
    const { transactionId } = paymentInfo.reduce(
      (previous: PaymentInfoResume, data: GetDeal_deal_paymentInfo) => {
        if (new Date(data.transactionTime).getTime() > new Date(previous.transactionTime).getTime()) {
          previous.transactionId = data.transactionId;
          previous.transactionTime = data.transactionTime;
        }
        return previous;
      },
      {
        transactionTime: Number.MIN_VALUE,
        transactionId: null,
      },
    );

    return transactionId;
  };
  const onMessage = (message: MessageEvent) => {
    const { urlResponseMP, isTransactionComplete, isErrorTransaction } = message?.data;

    if (urlResponseMP) {
      setCheckTransaction(isTransactionComplete);
      setUrlResponse(urlResponseMP);
      setIsFailed(isErrorTransaction);
    }
  };

  useEffect(() => {
    const status = deal?.status;
    const paymentInfo = deal?.paymentInfo;
    let transactionId;

    if (paymentInfo) {
      transactionId = getFirstElementPaymentId(paymentInfo);
    }

    if (status === TransactionErrorTypes.AUTHORIZED) {
      goToNextStep();
      return;
    }

    if (transactionId && status !== TransactionErrorTypes.COMPONENT_INIT) {
      setPaymentId(transactionId);
      return;
    }

    if (status === TransactionErrorTypes.FAILED) {
      setIsFailed(true);
      return;
    }
  }, [deal, loading]);

  useEffect(() => {
    if (checkTransaction && getUrlResponse) {
      window.top.location = getUrlResponse;
    }
  }, [getUrlResponse, checkTransaction]);

  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  return { isFailed, paymentId, getIframeUrl };
};
