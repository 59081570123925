import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { SavePaymentForm, SavePaymentFormVariables } from '../graphql/mutations/__generated__/SavePaymentForm';
import savePaymentForm from '../graphql/mutations/saveInStorePaymentForm';
import { IInstorePaymentForm } from '../model/IJsonSchema';
import { useHistory } from 'react-router';
import { JourneyStepsRoutes } from '../../../checkout4/enum/JourneyStepsRoutes';
import { UrlParamTypes } from '../../../../common/enum/UrlParamTypes';
import useUrlDealId from '../../../common/hooks/useUrlDealId';
import { GraphQLError } from 'graphql';

const useSaveInStorePaymentForm = (handleAsyncValidationErrors: (errors: readonly GraphQLError[]) => void) => {
  const [saveForm, { loading, error }] = useMutation<SavePaymentForm, SavePaymentFormVariables>(
    savePaymentForm.SAVE_FORM,
  );
  const history = useHistory();
  const dealId = useUrlDealId();

  const goToNextStep = useCallback(() => {
    history.replace({
      pathname: JourneyStepsRoutes.CONFIRMATION,
      search: `?${UrlParamTypes.TOKEN}=${dealId}`,
    });
  }, [history, dealId]);

  const onSubmit = useCallback(
    async ({ formData }: IInstorePaymentForm) => {
      const { data, errors } = await saveForm({ variables: { data: { formData: JSON.stringify(formData) } } });

      if (!errors && data) {
        goToNextStep();
      } else {
        handleAsyncValidationErrors(errors);
      }
    },
    [saveForm, handleAsyncValidationErrors, goToNextStep],
  );

  return { isSubmitting: loading, onSubmit, error };
};

export default useSaveInStorePaymentForm;
