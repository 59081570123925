import React, { FunctionComponent } from 'react';
import { Box, Button } from '@material-ui/core';
import buttonSubmitStyles from './buttonSubmitStyles';
import Translation from '../../../../../common/components/presentation/Translation/Translation';

interface IButtonSubmitProps {
  disabled: boolean;
}

const ButtonSubmit: FunctionComponent<IButtonSubmitProps> = ({ disabled }) => {
  const translationId = 'trans__se__instore_payment__submit_CTA';
  const styles = buttonSubmitStyles();

  return (
    <Box className={styles.submitButtonContainer}>
      <Button
        type="submit"
        disabled={disabled}
        className={styles.submitButton}
        classes={{ contained: styles.btnContained, containedPrimary: styles.newContainedPrimary }}
        variant="contained"
        color="primary"
        data-test="button:instorepayment_form:submit"
      >
        <Translation id={translationId} />
      </Button>
    </Box>
  );
};

export default ButtonSubmit;
