import { useQuery } from '@apollo/client';
import { LoadPaymentForm } from '../graphql/queries/__generated__/LoadPaymentForm';
import inStorePaymentForm from '../graphql/queries/inStorePaymentForm';
import { useCallback, useEffect, useState } from 'react';
import { IInstorePaymentForm, IInstorePaymentFormSchema, IInstorePaymentFormSchemaProps } from '../model/IJsonSchema';

const useInStorePaymentForm = () => {
  const { data, loading, error, refetch } = useQuery<LoadPaymentForm>(inStorePaymentForm.GET_PAYMENT_FORM_SCHEMA, {
    fetchPolicy: 'cache-and-network',
  });

  const [formData, setFormData] = useState<IInstorePaymentForm | IInstorePaymentFormSchema | null>(null);
  const [formSchema, setFormSchema] = useState(null);
  const [uiSchema, setUiSchema] = useState(null);
  const [requiredFields, setRequiredFields] = useState([]);

  const handleFormChange = useCallback((formData: IInstorePaymentFormSchema) => {
    setFormData(formData);
  }, []);

  const getRequiredFieldsOnLoad = useCallback((formSchema: IInstorePaymentFormSchemaProps[]) => {
    if (!formSchema) {
      return;
    }

    let required: string[] = [];

    Object.values(formSchema).forEach(
      (item: IInstorePaymentFormSchemaProps) => (required = required.concat(item?.required || [])),
    );
    setRequiredFields(required);
  }, []);

  useEffect(() => {
    if (!loading && data?.salesEnablement.paymentForm) {
      const newJSONFormData = JSON.parse(data.salesEnablement.paymentForm.formData);
      const parsedSchema = JSON.parse(data.salesEnablement.paymentForm.schema);

      setFormSchema(parsedSchema);
      setUiSchema(JSON.parse(data.salesEnablement.paymentForm.uiSchema));
      setFormData(newJSONFormData);
      getRequiredFieldsOnLoad(parsedSchema.properties);
    }
  }, [loading, data]);

  return {
    formData,
    handleFormChange,
    formSchema,
    uiSchema,
    requiredFields,
    error,
    refetch,
  };
};

export default useInStorePaymentForm;
