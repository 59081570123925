import { FinanceBreakdownItem } from "./customDatalayerInterface";

export const getReferrer = (): { from: string, to: string } => {
  const referrer = localStorage.getItem('referrer');
  const initial = { from: '', to: '' };
  if (referrer) {
    const parsed = JSON.parse(referrer);

    return parsed;
  }

  return initial;
}

const propertyName = 'AUD';
const audArrays: FinanceBreakdownItem[] = [];

export const extractAudArrays = (obj: { [key: string]: any }) => {
  for (const key in obj)
    key === propertyName && Array.isArray(obj[key])
      ? audArrays.push(obj[key])
      : typeof obj[key] === 'object' && extractAudArrays(obj[key]);
  return [].concat(...[].concat(...audArrays));
};

export const extractObjectFromBreakdown = (value: string, schema: string, isUpdate?: boolean) => {
  const breakdownData = extractAudArrays(JSON.parse(schema));

  if (isUpdate) {
    if (breakdownData.length > 0) {
      const data = breakdownData.filter((item: FinanceBreakdownItem) => item?.title.includes(value));

      return data[data.length -1].value
    }
    
      return undefined;
  }

  return breakdownData.length > 0
    ? breakdownData.find((item: FinanceBreakdownItem) => item?.title.includes(value))?.value
    : undefined;
};
