import usePrice from './usePrice';
import { PriceTypes } from '../../../../../../common/enum/PriceTypes';
import IPrice from '../../model/IPrice';

const useTotalPrices = (totalPrice: IPrice[]) => {
  const netPrices = totalPrice.filter(price => price.type === PriceTypes.NET_PRICE);
  const finalPrices = totalPrice.filter(price => price.type === PriceTypes.FINAL_PRICE);
  const { primaryPrice: primaryTotalNetPrice, secondaryPrice: secondaryTotalNetPrice } = usePrice(netPrices);
  const { primaryPrice: primaryTotalFinalPrice, secondaryPrice: secondaryTotalFinalPrice } = usePrice(finalPrices);

  const totalPrices = {
    totalNetPrices: [primaryTotalNetPrice, secondaryTotalNetPrice],
    totalFinalPrices: [primaryTotalFinalPrice, secondaryTotalFinalPrice],
  };

  return totalPrices;
};

export default useTotalPrices;
