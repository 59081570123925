import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import Translation from '../../../../common/components/presentation/Translation/Translation';
import ShowIfTranslationExists from '../../../../common/components/ShowIfTranslationExists';
import inStorePaymentStyles from './inStorePaymentStyles';
import InStorePaymentForm from '../../../../gql/commonSteps/inStorePaymentForm/components/inStorePaymentForm/InStorePaymentForm';

const InStorePayment: FunctionComponent = () => {
  const styles = inStorePaymentStyles();

  return (
    <Box className={styles.root} data-test="checkout:payment_in_store">
      <ShowIfTranslationExists translationKey="trans__se__instore_payment__title">
        <Typography className={styles.label}>
          <Translation id="trans__se__instore_payment__title" />
        </Typography>
      </ShowIfTranslationExists>
      <InStorePaymentForm />
    </Box>
  );
};

export default InStorePayment;
