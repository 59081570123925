import { createContext } from 'react';
import { GetDeal_deal } from 'src/gql/common/graphql/queries/__generated__/GetDeal';
import { ExpressionOfInterest } from '../graphql/fragments/deal/__generated__/ExpressionOfInterest';
import { ApolloError } from '@apollo/client';
import { StockType, PaymentType } from 'src/__generated__/globalTypes';
import { CustomerDetails } from '../graphql/fragments/__generated__/CustomerDetails';

type CheckoutContextProps = {
  deal: GetDeal_deal;
  setDeal?: (deal: GetDeal_deal) => void;
  loading: boolean;
  error?: ApolloError;
  refetch?: () => void;
  expressionOfInterest?: ExpressionOfInterest[];
  extrasPageHasContent?: boolean | null;
  isStock?: boolean;
  isJlrStockDiscount?: boolean;
  stockType?: StockType | null;
  paymentType?: PaymentType;
  setPaymentType?: (paymentType: PaymentType) => void;
  customerInformation?: CustomerDetails;
};

const CheckoutContextDefault: CheckoutContextProps = {
  deal: null,
  loading: false,
};

const CheckoutContext = createContext<CheckoutContextProps>(CheckoutContextDefault);

export default CheckoutContext;
