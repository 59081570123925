import React, { FunctionComponent, useCallback, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import DefaultButton from '../../../../common/components/presentation/Buttons/DefaultButton';
import FinanceCalculatorContainer from '../../../../common/components/financeCalculator/FinanceCalculatorContainer';
import useCheckoutContext from '../../../../gql/common/hooks/useCheckoutContext';
import ContinueButton from '../buttons/continueButton/ContinueButton';
import Translation from '../../../../common/components/presentation/Translation/Translation';
import headlessFinanceSectionStyles from './headlessFinanceSectionStyles';

const HeadlessFinanceSection: FunctionComponent = () => {
  const styles = headlessFinanceSectionStyles();
  const { deal } = useCheckoutContext();
  const [isFincalcOpen, setIsFincalcOpen] = useState(false);

  const toggleFinanceCalculator: (shouldOpen: boolean) => void = useCallback(
    shouldOpen => setIsFincalcOpen(shouldOpen),
    [setIsFincalcOpen],
  );

  return (
    <Box data-test={'checkout:headless_finance_section'} className={styles.financeWrapper}>
      <Typography className={styles.title}>
        <Translation id="trans__checkout__next_step__payment_method_section_title" />
      </Typography>
      <Typography className={styles.description}>
        <Translation id="trans__checkout__next_step__payment_method_section_description" />
      </Typography>
      <Box data-test={'checkout:headless_finance_section_actions'}>
        <ContinueButton testId="checkout:continue_button:headless_finance" activateNextStep={true} />
        <DefaultButton
          className={[styles.buttonContainer]}
          onClick={() => setIsFincalcOpen(true)}
          dataTest="checkout:headless_finance_section:button"
          variant="outlined"
        >
          <Translation id="trans__checkout__next_step__payment_method_section_review_payment_option_CTA" />
        </DefaultButton>
      </Box>
      <FinanceCalculatorContainer
        isModalOpen={isFincalcOpen}
        toggleModal={toggleFinanceCalculator}
        carConfiguration={deal.car?.code}
      />
    </Box>
  );
};

export default HeadlessFinanceSection;
