import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MinimalLoader from '../../../../../../common/components/presentation/MinimalLoader/MinimalLoader';
import useWidgetContext from '../../../../../../common/react/useWidgetContext';
import { assetManager } from '../../../../../../../../common/service/assetManager';
import CONSTANTS from '../../../../../../common/constants/constants';
import errorHandler from '../../../../../common/service/errorHandler';
import { PAYMENT_CONFIG } from '../../../../../../common/constants/payment';

interface IStripeProps {
  dealStatus: string;
}

const Stripe: FunctionComponent<IStripeProps> = ({ dealStatus }) => {
  const widgetId = useMemo(() => 'dxp-payment-container' + Math.floor(Math.random() * 100), []);
  const widgetInstance: any = useRef(null);
  const paymentLibrary: any = useRef(null);
  const { configuration } = useWidgetContext();
  const [isLoading, setLoadingStatus] = useState(false);

  const ensureLibraryLoaded = () => {
    const widgetUrl: string = PAYMENT_CONFIG.dxpWidgetUrl;

    if (assetManager.isScriptLoaded(widgetUrl)) {
      if (!paymentLibrary.current && (window as any).DXPPayment) {
        paymentLibrary.current = (window as any).DXPPayment;
      }

      return Promise.resolve();
    }

    return Promise.resolve()
      .then(() => {
        // keep in mind there's a slight difference between the react versions of dxp and auto-widgets
        if (!assetManager.isScriptLoaded(CONSTANTS.REACT_DEPENDENCIES.react18)) {
          return assetManager
            .loadScript(CONSTANTS.REACT_DEPENDENCIES.react18)
            .then(() => assetManager.loadScript(CONSTANTS.REACT_DEPENDENCIES.reactDom18));
        }
      })
      .then(() => assetManager.loadScript(widgetUrl, 'module'))
      .then(() => {
        paymentLibrary.current = (window as any).DXPPayment;
      });
  };

  const getLibrary = useCallback(() => {
    if (!paymentLibrary.current) {
      throw new Error(`Attempted to use DXP Payment library without loading it first`);
    }

    return paymentLibrary.current;
  }, [paymentLibrary.current]);

  const initializeWidget = () => {
    if (widgetInstance.current) {
      widgetInstance.current.destroy();
    }

    setLoadingStatus(true);

    const widgetConfig: any = {
      containerId: widgetId,
      dimensions: configuration.dimensions,
      dealStatus: dealStatus,
    };

    const dxpWidget = getLibrary();
    dxpWidget.createWidget(widgetConfig);
  };

  useEffect(() => {
    if (!widgetInstance.current) {
      ensureLibraryLoaded()
        .then(() => initializeWidget())
        .catch(errorHandler.promise())
        .finally(() => setLoadingStatus(false));
    }

    return () => {
      if (widgetInstance.current) {
        widgetInstance.current.destroy();
      }
    };
  }, []);

  return (
    <>
      {isLoading && <MinimalLoader />}
      <div id={widgetId} data-test="payment:stripe" />
    </>
  );
};

export default Stripe;
