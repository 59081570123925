import { useCallback, useEffect } from 'react';
import { IEnquiryForm, IEnquiryFormSchema } from '../model/IJsonSchema';
import enquiryForm from '../graphql/mutations/saveEnquiryForm';
import { ApolloError, useMutation } from '@apollo/client';
import {
  SaveEnquiryForm,
  SaveEnquiryForm_saveEnquiryForm_paymentInfo,
  SaveEnquiryFormVariables,
} from '../graphql/mutations/__generated__/SaveEnquiryForm';
import CONSTANTS from '../../../../common/constants/constants';
import useCheckoutContext from '../../../common/hooks/useCheckoutContext';
import useNavigationSteps from '../../../checkout3/common/hooks/useNavigationSteps';
import useCallDxpDigitalAssignmentEntryEvent from '../../../common/hooks/useDxpDigitalAssignmentEntryEvent';
import usePlatformConfig from '../../../../common/react/usePlatformConfig';

const useEnquiryFormSubmit = (
  handleAsyncValidationErrors: (error: ApolloError) => void,
  token: string,
  goToNextStep: (data: Record<string, SaveEnquiryForm_saveEnquiryForm_paymentInfo[]>) => void,
  stateId: string,
) => {
  const [saveForm, { loading, error }] = useMutation<SaveEnquiryForm, SaveEnquiryFormVariables>(enquiryForm.SAVE_FORM);
  const { setDeal } = useCheckoutContext();
  const { steps, activeStep } = useNavigationSteps();
  const { callDxpDigitalAssignmentEntryEvent } = useCallDxpDigitalAssignmentEntryEvent();
  const {
    platformConfig: {
      platformFeatures: { dataLayer },
      fetchBreakdownJsonSchema,
    },
  } = usePlatformConfig();

  const removeTradeIn = useCallback((formData: IEnquiryFormSchema) => {
    delete formData.tradeIn;
    formData.tradeIn = {};
    formData.tradeIn.tradeInCheckbox = false;
  }, []);

  useEffect(() => {
    if (error) {
      handleAsyncValidationErrors(error);
    }
  }, [error]);

  const onSubmit = useCallback(
    ({ formData }: IEnquiryForm) => {
      (window as any).grecaptcha?.ready(() => {
        (window as any).grecaptcha
          .execute(CONSTANTS.RECAPTCHA_SITE_KEY, { action: 'submit' })
          .then(async (captchaToken: string) => {
            if (!formData?.tradeIn?.tradeInCheckbox) {
              removeTradeIn(formData);
            }
            const deal = await saveForm({
              variables: {
                dealId: token,
                state: stateId,
                captchaToken: captchaToken,
                data: { formData: JSON.stringify(formData) },
                dataLayer: !!dataLayer,
                fetchBreakdownJsonSchema: fetchBreakdownJsonSchema ?? true,
              },
            });

            if (!deal.errors) {
              setDeal(deal.data.saveEnquiryForm);
              callDxpDigitalAssignmentEntryEvent(deal.data.saveEnquiryForm, steps, activeStep.index + 1, {
                pageName: steps[activeStep.index + 1].pathname,
                pageLocation: steps[activeStep.index + 1].pathname,
              });
              goToNextStep({ previousTransactions: deal.data.saveEnquiryForm.paymentInfo });
            } else {
              handleAsyncValidationErrors((deal.errors as unknown) as ApolloError);
            }
          });
      });
    },
    [token, stateId],
  );

  return { isSubmitting: loading, onSubmit, error };
};

export default useEnquiryFormSubmit;
