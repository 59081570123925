import React, { FunctionComponent } from 'react';
import SalesEnablementContext from '../../context/SalesEnablementContext';
import useSalesEnablementCommunication from '../../hooks/useSalesEnablementCommunication';

const SalesEnablementContextProvider: FunctionComponent = ({ children }) => {
  const {
    isInSalesEnablement,
    sessionId,
    sendIsInCheckoutPage,
    dealVersion,
    isDealExported,
  } = useSalesEnablementCommunication();

  return (
    <SalesEnablementContext.Provider
      value={{
        isInSalesEnablement,
        sessionId,
        sendIsInCheckoutPage,
        dealVersion,
        isDealExported,
      }}
    >
      {children}
    </SalesEnablementContext.Provider>
  );
};

export default SalesEnablementContextProvider;
