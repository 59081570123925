export enum JourneySteps {
  JourneySelection = 'journeySelection',
  Dealer = 'dealer',
  EnquiryForm = 'enquiryForm',
  Payment = 'payment',
  FinanceInsurance = 'financeInsurance',
  Finance = 'finance',
  IdentityVerification = 'identityVerification',
  Extras = 'extras',
  Accesories = 'accesories',
  HeadlessFinance = 'headlessFinance',
}
