import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type PriceInputClasses = 'currency';

const priceInputStyles = makeStyles<ICheckoutTheme, PriceInputClasses>(
  () => ({
    currency: {
      paddingLeft: 7,
      marginRight: 'unset',
    },
  }),
  { name: 'GqlCheckout_InStorePaymentForm_PriceInput' },
);

export default priceInputStyles;
