import React, { FC, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Chip, Grid, Typography, useTheme } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Translation from '../../../../common/components/presentation/Translation/Translation';
import { activeJourneyType } from '../../../common/graphql/cache';
import { Journeys } from '../../constants/JourneySteps';
import { JourneyStepsRoutes } from '../../enum/JourneyStepsRoutes';
import { useReactiveVar } from '@apollo/client';
import { JourneyType } from '../../../../__generated__/globalTypes';
import useUrlSearchParams from '../../../../common/hooks/useUrlSearchParams';
import { RedirectOrigins } from '../../enum/RedirectOrigins';
import useWidgetContext from '../../../../common/react/useWidgetContext';
import { MyAccountRoutes } from '../../enum/MyAccountRoutes';
import { UrlParamTypes } from '../../../../common/enum/UrlParamTypes';
import { useCancelVehicleReservation } from '../../hooks/mutations/useCancelVehicleReservation';
import useSalesEnablementCommunication from '../../../../gql/common/hooks/useSalesEnablementCommunication';
import useBackLink from '../../hooks/useBackLink';
import useCheckoutContext from '../../../common/hooks/useCheckoutContext';
import { ICheckoutTheme } from '../../../../common/theme/ICheckoutTheme';
import errorHandler from '../../../common/service/errorHandler';
import useCheckDealStatus from '../../../../../src/gql/checkout4/hooks/useCheckDealStatus';
import navigationStyles from './navigationStyles';

const Navigation: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = useUrlSearchParams();
  const {
    configuration: { myAccountUrl },
  } = useWidgetContext();
  const {
    checkoutLite: { checkout4 },
  } = useTheme<ICheckoutTheme>();
  const activeJourney = useReactiveVar(activeJourneyType);
  const selectedJourney = Journeys.find(journey => journey.journeyType === activeJourney);
  const { deal } = useCheckoutContext();
  const configurationSourceUrl = useMemo(() => deal?.car?.configurationSourceUrl, [deal]);
  const { removeSoftLock, loading: loadingRemoveSoftLock } = useCancelVehicleReservation();
  const { isInConfirmationPage } = useSalesEnablementCommunication();
  const isDisabledInSe = isInConfirmationPage(location);

  const getRedirectUrl = useCallback(
    (path: string) => {
      const queryString = Object.entries(searchParams).reduce((prev, [key, value]) => {
        if (!key && !value && key === UrlParamTypes.FROM) {
          return prev;
        }
        return `${prev}${prev === '' ? '?' : '&'}${key === UrlParamTypes.TOKEN ? 'dealId' : key}=${value}`;
      }, '');

      return `${myAccountUrl}${path}${queryString}`;
    },
    [searchParams, myAccountUrl],
  );
  const { backLinkUrl, isOverride } = useBackLink();

  const hideBackLink =
    checkout4?.hideBackLink ||
    (checkout4?.hideBackLinkWhenRedirectedFromMyAccount &&
      Object.values(RedirectOrigins).includes(searchParams?.from as RedirectOrigins));

  const disableBackLink =
    checkout4?.disableBackLinkWhenRedirectedFromMyAccount &&
    Object.values(RedirectOrigins).includes(searchParams?.from as RedirectOrigins);

  const handleRedirect = useCallback(() => {
    switch (searchParams?.from) {
      case RedirectOrigins.ENQUIRY:
        window.location.href = getRedirectUrl(MyAccountRoutes.MYACCOUNT_ENQUIRY);
        break;
      case RedirectOrigins.ORDERS:
        window.location.href = getRedirectUrl(MyAccountRoutes.MYACCOUNT_ORDERS);
        break;
      case RedirectOrigins.RESERVATIONS:
        window.location.href = getRedirectUrl(MyAccountRoutes.MYACCOUNT_RESERVATIONS);
        break;
      case RedirectOrigins.SAVED_CARS:
        window.location.href = getRedirectUrl(MyAccountRoutes.MYACCOUNT_SAVED_CARS);
        break;
      case RedirectOrigins.MY_ACCOUNT:
        history.goBack();
        break;
      default:
        const backUrl = !!deal?.car?.stock
          ? `${configurationSourceUrl}?${UrlParamTypes.REVERT_TO_BYO}=${deal?.car?.code}`
          : configurationSourceUrl;

        window.location.href = backUrl;
        break;
    }
  }, [searchParams?.from, deal?.car?.stock]);

  const handleBackButton = useCallback(async () => {
    try {
      await removeSoftLock();
      if (isOverride) {
        window.location.href = backLinkUrl;
        return;
      }

      handleRedirect();
    } catch (error) {
      errorHandler.handleError(error);
    }
  }, [isOverride, backLinkUrl, deal, configurationSourceUrl, handleRedirect]);

  const headerTitle = useCallback(() => {
    switch (location.pathname) {
      case JourneyStepsRoutes.CONFIRMATION:
        if (activeJourney) {
          return {
            [JourneyType.continue_in_dealership]: 'trans__checkout__confirmation_page__title_enquiry_journey',
            [JourneyType.reserve_online]: 'trans__checkout__confirmation_page__title_reserve_journey',
            [JourneyType.online_order]: 'trans__checkout__next_step__confirmation_page__order_journey_title',
          }[activeJourney as Exclude<JourneyType, JourneyType.save_my_build | JourneyType.pre_order_now>];
        } else {
          return 'trans__checkout__next_step__confirmation_page_label';
        }
      case JourneyStepsRoutes.NEXT_STEPS:
        return 'trans__checkout__your_next_step_label';
      default:
        if (!!selectedJourney) {
          return selectedJourney.title;
        } else {
          return 'trans__checkout__your_next_step_label';
        }
    }
  }, [selectedJourney, location.pathname]);

  const { storedIsDealExportValid } = useCheckDealStatus();
  const styles = navigationStyles();

  return (
    // dxp-id - added for widget integration
    <Grid container id="dxp-checkout-navigation" className={styles.root}>
      <Grid item xs={12} className={styles.wrapper}>
        {!hideBackLink && (
          <Box className={styles.backContainer}>
            <Chip
              classes={{
                outlined: styles.backButton,
                label: styles.backLabel,
                icon: styles.backIcon,
              }}
              variant="outlined"
              onClick={handleBackButton}
              icon={<ArrowBackIosIcon />}
              label={<Translation id="trans__checkout__general__back_link" />}
              data-test="checkout:navigation:backButton"
              disabled={loadingRemoveSoftLock || disableBackLink || isDisabledInSe || storedIsDealExportValid}
            />
          </Box>
        )}
        <Box className={styles.headerContainer}>
          <Typography variant="body1" className={styles.headerTitle} data-test="checkout:navigation:header:title">
            <Translation id={headerTitle()} />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
export default Navigation;
