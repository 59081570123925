import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ISchemaFieldProps } from 'src/enquiryForm/model/IJsonSchema';
import GenericInput from '../../../../common/components/genericInput/GenericInput';

const PhoneWithPrefixInput: FunctionComponent<ISchemaFieldProps> = ({ formData }) => {
  const {
    label,
    required,
    id,
    onChange,
    value,
    rawErrors,
    disabled,
    options: { inputLabel },
  } = formData;
  const currentError = useMemo(() => rawErrors?.length > 0 && rawErrors[0], [rawErrors]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<{ value: string }>) => {
      onChange(e.target.value as string);
    },
    [value],
  );

  return (
    <GenericInput
      label={label}
      required={required}
      id={id}
      value={value}
      currentError={currentError}
      onChange={handleChange}
      inputLabel={inputLabel}
      phoneWithPrefix={true}
      disabled={disabled}
    />
  );
};

export default PhoneWithPrefixInput;
