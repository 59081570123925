import React, { FunctionComponent, useCallback, useMemo } from 'react';
import useInStorePaymentForm from '../../hooks/useInStorePaymentForm';
import Form from 'react-jsonschema-form';
import { Box } from '@material-ui/core';
import CustomComponentsMapper from '../../components/CustomComponentsMapper';
import ObjectFieldTemplate from '../objectFieldTemplate/ObjectFieldTemplate';
import MinimalLoader from '../../../../../common/components/presentation/MinimalLoader/MinimalLoader';
import ThemedErrorMessage from '../../../../../common/components/presentation/Error/ThemedErrorMessage';
import inStorePaymentFormStyles from './inStorePaymentFormStyles';
import ButtonSubmit from '../../components/buttonSubmit/ButtonSubmit';
import useCustomErrorMessagesMapper from '../../hooks/useCustomErrorMessagesMapper';
import useSaveInStorePaymentForm from '../../hooks/useSaveInStorePaymentForm';
import useInStorePaymentFormValidate from '../../hooks/useInStorePaymentFormValidate';
import { IInstorePaymentForm, IInstorePaymentFormSchema } from '../../model/IJsonSchema';

const InStorePaymentForm: FunctionComponent = () => {
  const { formData, formSchema, uiSchema, error, handleFormChange, requiredFields } = useInStorePaymentForm();
  const {
    handleAsyncValidationErrors,
    resetAsyncErrors,
    handleValidationErrors,
    asyncErrors,
    hasErrors,
  } = useInStorePaymentFormValidate();
  const { onSubmit, isSubmitting } = useSaveInStorePaymentForm(handleAsyncValidationErrors);
  const styles = inStorePaymentFormStyles();
  const customWidgets = useMemo(() => CustomComponentsMapper(), []);
  const customErrorMessages = useCustomErrorMessagesMapper(formData);

  const onChange = useCallback(
    (data: IInstorePaymentForm) => {
      resetAsyncErrors();
      handleFormChange(data.formData);

      handleValidationErrors(requiredFields, data);
    },
    [resetAsyncErrors, handleFormChange, handleValidationErrors, requiredFields],
  );

  if (error) {
    return <ThemedErrorMessage fullPage={true} error={error} />;
  }

  if (!formData || !formSchema || !uiSchema) {
    return <MinimalLoader />;
  }

  return (
    <Box className={styles.formWrapper} data-test="container:checkout:instorepayment_form">
      <Form<IInstorePaymentFormSchema>
        schema={formSchema}
        uiSchema={uiSchema}
        formData={formData}
        onChange={onChange}
        onSubmit={onSubmit}
        submitOnEnter={true}
        liveValidate
        transformErrors={customErrorMessages}
        extraErrors={asyncErrors}
        showErrorList={false}
        widgets={customWidgets}
        ObjectFieldTemplate={ObjectFieldTemplate}
      >
        <ButtonSubmit disabled={hasErrors || isSubmitting} />
      </Form>
    </Box>
  );
};

export default InStorePaymentForm;
