import React, { ReactElement } from 'react';
import DropdownSelect from './dropdownSelect/DropdownSelect';
import InputField from './inputField/InputField';
import { ISchemaField } from '../model/IJsonSchema';
import { CustomCheckbox } from './customCheckbox/CustomCheckbox';
import DatePicker from '../../../common/components/dateField/DatePicker';
import InputFieldWithHyphen from './inputFieldWithHyphen/InputFieldWithHyphen';
import AutocompleteInput from './autocompleteInput/AutocompleteInput';
import PhoneWithPrefixInput from './phoneWithPrefixField/PhoneWIthPrefixInput';
import MarkdownText from './markdownText/MarkdownText';
import CustomCheckboxes from './customCheckboxes/CustomCheckboxes';
import TextareaInput from './textareaInput/TextareaInput';
import CustomRadioButton from './customRadioButton/CustomRadioButton';
import CustomRadioGroup from './customRadioGroup/CustomRadioGroup';
import TextareaAutosizeInput from './textareaAutosizeInput/TextareaAutosizeInput';

interface ICustomComponentsMapperReturn {
  TextWidget: (inputData: ISchemaField) => ReactElement;
  SelectWidget: (radioData: ISchemaField) => ReactElement;
  CheckboxWidget: (checkboxData: ISchemaField) => ReactElement;
  CheckboxesWidget: (checkboxData: ISchemaField) => ReactElement;
  EmailWidget: (inputData: ISchemaField) => ReactElement;
  InputWithHyphen: (inputData: ISchemaField) => ReactElement;
  PhoneWithPrefix: (inputData: ISchemaField) => ReactElement;
  MarkdownText: (inputData: ISchemaField) => ReactElement;
  TextareaWidget: (inputData: ISchemaField) => ReactElement;
  RadioWidget: (radioData: ISchemaField) => ReactElement;
  RadioGroupWidget: (radioData: ISchemaField) => ReactElement;
  TextareaAutosizeWidget: (inputData: ISchemaField) => ReactElement;
}

type ICustomSchemaMapper = () => ICustomComponentsMapperReturn;

const CustomComponentsMapper: ICustomSchemaMapper = () => {
  return {
    TextWidget: (inputData: ISchemaField) => <InputField formData={inputData} />,
    SelectWidget: (selectData: ISchemaField) => <DropdownSelect formData={selectData} />,
    CheckboxWidget: (checkboxData: ISchemaField) => <CustomCheckbox formData={checkboxData} />,
    CheckboxesWidget: (checkboxData: ISchemaField) => <CustomCheckboxes formData={checkboxData} />,
    EmailWidget: (emailData: ISchemaField) => <InputField formData={emailData} />,
    DateWidget: (dateData: ISchemaField) => <DatePicker formData={dateData} />,
    InputWithHyphen: (inputData: ISchemaField) => <InputFieldWithHyphen formData={inputData} />,
    Autocomplete: (inputData: ISchemaField) => <AutocompleteInput formData={inputData} />,
    PhoneWithPrefix: (inputData: ISchemaField) => <PhoneWithPrefixInput formData={inputData} />,
    MarkdownText: (inputData: ISchemaField) => <MarkdownText formData={inputData} />,
    TextareaWidget: (inputData: ISchemaField) => <TextareaInput formData={inputData} />,
    TextareaAutosizeWidget: (inputData: ISchemaField) => <TextareaAutosizeInput formData={inputData} />,
    RadioWidget: (radioData: ISchemaField) => <CustomRadioButton formData={radioData} />,
    RadioGroupWidget: (radioData: ISchemaField) => <CustomRadioGroup formData={radioData} />,
  };
};

export default CustomComponentsMapper;
