import { useMemo } from 'react';
import useWidgetContext from '../react/useWidgetContext';
import { CUSTOMER_STORAGE, getDimensionalCustomerStorage } from '../../../../common/service/customerStorage';
import usePlatformConfig from '../react/usePlatformConfig';

const useCustomerStorage = () => {
  const {
    configuration: { dimensions },
  } = useWidgetContext();
  const {
    platformConfig: { sharedLocalStorageKeys },
  } = usePlatformConfig();

  const isCustomerDataShared = sharedLocalStorageKeys ? sharedLocalStorageKeys.includes(CUSTOMER_STORAGE) : false;

  return {
    customerStorage: useMemo(() => {
      return getDimensionalCustomerStorage(dimensions, isCustomerDataShared);
    }, [dimensions]),
  };
};

export default useCustomerStorage;
