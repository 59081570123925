import React, { FunctionComponent, useCallback, useMemo, ChangeEvent } from 'react';
import { ISchemaFieldProps } from '../../model/IJsonSchema';
import GenericInput from '../../../../common/components/genericInput/GenericInput';
import priceInputStyles from './priceInputStyles';

export const PriceInput: FunctionComponent<ISchemaFieldProps> = ({ formData }) => {
  const styles = priceInputStyles();
  const {
    label,
    required,
    id,
    onChange,
    value,
    rawErrors,
    options: { inputLabel },
    placeholder,
    schema: { readOnly },
    disabled,
  } = formData;
  const currentError = useMemo(() => rawErrors?.length > 0 && rawErrors[0], [rawErrors]);

  const handleChange = useCallback(
    (e: ChangeEvent<{ value: string }>) => {
      const numericString = e.target.value;
      const regex = /[^\d-]/g;
      const newValue = numericString.replace(regex, '');
      onChange(newValue);
    },
    [value],
  );

  return (
    <GenericInput
      inputMode="numeric"
      label={label}
      required={required}
      id={id}
      value={value}
      currentError={currentError}
      onChange={handleChange}
      inputLabel={inputLabel}
      placeholder={placeholder}
      readOnly={readOnly}
      disabled={disabled}
      classes={{ startAdornment: styles.currency }}
      displayCurrency
    />
  );
};

export default PriceInput;
