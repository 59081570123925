/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DepositType {
  absolute = 'absolute',
  percentage = 'percentage',
}

export enum DisabledDeal {
  dealExpired = 'dealExpired',
  dealInfoOutOfDate = 'dealInfoOutOfDate',
  dealNotAvailable = 'dealNotAvailable',
  financeApplicationExists = 'financeApplicationExists',
  modifiedByDealer = 'modifiedByDealer',
}

export enum FinanceFormType {
  cash = 'cash',
  finance = 'finance',
}

export enum FinanceResultType {
  cash = 'cash',
  error = 'error',
  finance = 'finance',
  na = 'na',
  not_applicable = 'not_applicable',
}

export enum JourneyType {
  continue_in_dealership = 'continue_in_dealership',
  online_order = 'online_order',
  pre_order_now = 'pre_order_now',
  reserve_online = 'reserve_online',
  save_my_build = 'save_my_build',
}

export enum LoanTermPeriodUnit {
  month = 'month',
}

export enum MyAccountRedirectTarget {
  login = 'login',
  setPassword = 'setPassword',
}

export enum PaymentType {
  full_payment = 'full_payment',
  order_car = 'order_car',
  reservation_fee = 'reservation_fee',
}

export enum StockType {
  dealer = 'dealer',
  distribution = 'distribution',
}

export interface EnquiryFormData {
  formData?: string | null;
}

export interface SalesEnablementPaymentFormData {
  formData?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
