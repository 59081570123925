import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type InStorePaymentFormClasses = 'formWrapper';

const inStorePaymentFormStyles = makeStyles<ICheckoutTheme, InStorePaymentFormClasses>(
  ({ breakpoints, spacing }) => ({
    formWrapper: {
      justifyContent: 'center',
      '& form': {
        width: '100%',
      },

      '& .form-group.field.field-string.field-error.has-error.has-danger ul li': {
        display: 'none',
      },

      '& .error-detail': {
        display: 'none',
      },

      [breakpoints.down(breakpoints.values.sm)]: {
        padding: () => `0 ${spacing(1.5)}`,
      },
    },
    submitButtonContainer: {
      margin: '20px 0',
    },
    submitButton: {
      marginLeft: 0,
      width: '100%',
    },
  }),
  { name: 'GqlCheckout_InStorePaymentForm' },
);

export default inStorePaymentFormStyles;
