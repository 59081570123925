import { hot } from 'react-hot-loader/root';
import React, { FunctionComponent } from 'react';
import ThemeProvider from './ThemeProvider';
import httpClient from '../api/httpClient';
import useWidgetContext from '../react/useWidgetContext';
import TranslationProvider from './TranslationProvider';
import ParallelContextualizedInitializer from './ParallelContextualizedInitializer';
import MinimalLoader from './presentation/MinimalLoader/MinimalLoader';
import WidgetContent from './WidgetContent';
import PlatformConfigProvider from '../react/PlatformConfigProvider';
import PlatformJsonnetConfigProvider from '../react/PlatformJsonnetConfigProvider';
import SessionDataProvider from './SessionDataProvider';
import GqlSessionDataProvider from '../../gql/common/components/SessionDataProvider';
import useGqlRestSwitch from '../../gql/common/hooks/useGqlRestSwitch';
import { ApolloProvider } from '@apollo/client';
import useApolloClient from '../../gql/common/hooks/useApolloClient';
import GqlTranslationsProvider from '../../gql/common/components/providers/GqlTranslationProvider';
import SalesEnablementContextProvider from '../../gql/common/components/providers/SalesEnablementContextProvider';
import TagManager from 'react-gtm-module';
import CONSTANTS from '../constants/constants';
import useNewJsonnetApi from '../../../../common/utilities/useNewJsonnetApi';

const CheckoutLiteWidget: FunctionComponent<any> = () => {
  const { configuration } = useWidgetContext();
  const { isGqlApi } = useGqlRestSwitch();
  const { client } = useApolloClient();
  const useNewApi = useNewJsonnetApi(configuration.dimensions);
  httpClient.configure(configuration.cookieDomain, configuration.dimensions);
  TagManager.initialize({ gtmId: CONSTANTS.GTM_ID });

  return (
    <ApolloProvider client={client}>
      <ParallelContextualizedInitializer
        initializers={[
          ThemeProvider,
          useNewApi ? PlatformJsonnetConfigProvider : PlatformConfigProvider,
          isGqlApi ? GqlTranslationsProvider : TranslationProvider,
        ]}
        loader={<MinimalLoader />}
      >
        <SalesEnablementContextProvider>
          <ParallelContextualizedInitializer
            initializers={[isGqlApi ? GqlSessionDataProvider : SessionDataProvider]}
            loader={<MinimalLoader />}
          >
            <WidgetContent />
          </ParallelContextualizedInitializer>
        </SalesEnablementContextProvider>
      </ParallelContextualizedInitializer>
    </ApolloProvider>
  );
};

export default hot(CheckoutLiteWidget);
