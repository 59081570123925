import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import PaymentDisclaimer from './paymentDisclaimer/PaymentDisclaimer';
import PaymentPage from './PaymentPage';
import useCheckoutContext from '../../../common/hooks/useCheckoutContext';
import { DealStatus } from '../../enum/DealStatus';
import SelectPaymentAmount from './selectPaymentAmount/SelectPaymentAmount';
import usePlatformConfig from '../../../../common/react/usePlatformConfig';
import { IJourneyConfig } from '../../../../common/model/IPlatformConfig';
import { JourneyType, PaymentType } from '../../../../__generated__/globalTypes';
import dataLayerToggleVerification from '../../../../../../common/utilities/dataLayerToggleVerification';
import useWidgetContext from '../../../../common/react/useWidgetContext';
import { activeJourneyType } from '../../../common/graphql/cache';
import useCallDigitalAssignmentEntryEvent from '../../../common/hooks/dataLayer/useCallDigitalAssignmentEntryEvent';
import { DataLayerFormStage } from '../../../../../../common/utilities/customDatalayerInterface';
import useJourneyStepsContext from '../../hooks/useJourneyStepsContext';
import { ScrollInViewBehaviourType, ScrollInViewBlockType } from '../../../../common/enum/ScrollInViewTypes';
import useIsTablet from '../../../../common/react/useIsTablet';
import { useTheme } from '@material-ui/core';
import { ICheckoutTheme } from 'src/common/theme/ICheckoutTheme';
import { TransactionErrorTypes } from '../../../../../src/common/enum/TransactionErrorTypes';
import PAYMENT_TYPE from '../../../../../src/gql/common/constants/paymentType';
import InStorePayment from './InStorePayment';
import useSalesEnablementContext from '../../../../../../checkout_lite/src/gql/common/hooks/useSalesEnablementContext';

const PaymentContainer: FunctionComponent = () => {
  const isTablet = useIsTablet();
  const { deal } = useCheckoutContext();
  const { isInSalesEnablement } = useSalesEnablementContext();
  const {
    configuration: { dimensions },
  } = useWidgetContext();
  const {
    platformConfig: { checkout, paymentProvider },
  } = usePlatformConfig();
  const { checkoutLite } = useTheme<ICheckoutTheme>();
  const { scrollAdjustmentToPaymentMethodStep } = checkoutLite?.checkout4 ?? {};
  const journeys = checkout?.journeys || [];
  const isPaymentProcessed = useMemo(
    () => deal?.status === DealStatus.AUTHORIZED || deal?.status === DealStatus.FAILED,
    [deal],
  );
  const [displayPayment, setDisplayPayment] = useState(isPaymentProcessed);
  const journeyType = activeJourneyType();
  const isDataLayer = dataLayerToggleVerification(dimensions);
  const { callDigitalAssignmentEntryEvent } = useCallDigitalAssignmentEntryEvent();
  const { activeStep } = useJourneyStepsContext();
  const isStripeAndInPaymentInitOrCancelled =
    paymentProvider === PAYMENT_TYPE.STRIPE &&
    (deal.status === TransactionErrorTypes.PAYMENT_INIT || deal.status === TransactionErrorTypes.CANCELLED);

  const showPayment = useCallback(() => {
    if (isDataLayer && journeyType === JourneyType.online_order) {
      callDigitalAssignmentEntryEvent({ deal, dimensions, stage: DataLayerFormStage.PAYMENT_METHOD });
    }

    setDisplayPayment(true);

    if (scrollAdjustmentToPaymentMethodStep && isTablet) {
      const stepElement = document.querySelector(`[data-test="checkout:step_${activeStep}"]`);

      setTimeout(() => {
        stepElement?.scrollIntoView({ behavior: ScrollInViewBehaviourType.SMOOTH, block: ScrollInViewBlockType.START });
      }, 150);
    }
  }, [displayPayment, journeyType, isDataLayer, scrollAdjustmentToPaymentMethodStep, isTablet]);

  const showPaymentAmountSelectionComponent: boolean = useMemo(() => {
    const availablePayment = journeys?.find(
      (journey: IJourneyConfig) =>
        null != journey?.availablePaymentTypes && journey?.journeyType === JourneyType.reserve_online,
    );

    if (!availablePayment) {
      return false;
    }

    return (availablePayment?.availablePaymentTypes?.cash?.types || []).some(
      (pt: keyof typeof PaymentType) => pt === PaymentType.full_payment,
    );
  }, [journeys]);

  if (isInSalesEnablement) {
    return <InStorePayment />;
  }

  if (displayPayment || isStripeAndInPaymentInitOrCancelled) {
    return <PaymentPage deal={deal} />;
  }

  return (
    <>
      {showPaymentAmountSelectionComponent && deal?.finance?.type === 'cash' && <SelectPaymentAmount />}
      <PaymentDisclaimer handleClick={showPayment} />
    </>
  );
};

export default PaymentContainer;
