import React from 'react';
import universal, { UniversalComponent } from 'react-universal-component';
import MinimalLoader from '../../common/components/presentation/MinimalLoader/MinimalLoader';
import errorHandler from '../common/service/errorHandler';
import ThemedErrorMessage from '../../common/components/presentation/Error/ThemedErrorMessage';
import IThemableComponent from '../../common/theme/IThemableComponent';
import { CheckoutLayoutClasses } from './components/layout/checkoutLayoutStyles';
import { BannerStylesClasses } from './components/banner/bannerStyles';
import { JourneyStepsClasses } from './components/journeySteps/journeyStepsStyles';
import { NavigationClasses } from './components/navigation/navigationStyles';
import { JourneyListClasses } from './components/journeyList/journeyListStyles';
import { JourneySummaryClasses } from './components/journeySummary/journeySummaryStyles';
import { FinanceAndInsuranceClasses } from './components/financeAndInsurance/financeAndInsuranceStyles';
import { FinanceSectionClasses } from './components/finance/financeSectionStyles';
import { PaymentDisclaimerClasses } from './components/payment/paymentDisclaimer/paymentDisclaimerStyles';
import { PaymentSummaryClasses } from './components/payment/paymentSummary/paymentSummaryStyles';
import { SidebarControlsLayoutClasses } from './components/sidebarControls/sidebarControlsStyles';
import { CarDetailsClasses } from './components/sidebarControls/carDetails/carDetailsStyles';
import { VehicleTotalClasses } from './components/carOptionsSummary/vehicleTotalPrice/vehicleTotalStyles';
import { SelectionsClasses } from './components/carOptionsSummary/selections/selectionsStyles';
import { TradeInButtonStylesClasses } from './components/common/salesSummary/tradeInButton/tradeInButtonStyles';
import { FullModelNameClasses } from './components/common/fullModelName/fullModelNameStyles';
import { OfferSummaryClasses } from './components/carOptionsSummary/offers/offerSummaryStyles';
import { DiscountSummaryStylesClasses } from './components/carOptionsSummary/discounts/discountSummaryStyles';
import { OptionSummaryStylesClasses } from './components/carOptionsSummary/selections/accessoriesOptions/optionSummaryStyles';
import { ContinueButtonClasses } from './components/buttons/continueButton/continueButtonStyles';
import { UpdateOrderButtonClasses } from './components/buttons/updateOrderButton/updateOrderButtonStyles';
import { SaveMyBuildButtonClasses } from './components/saveMyBuild/components/SaveMyBuildButton/saveMyBuildButtonStyles';
import { DisclaimerClasses } from './components/footer/disclaimerStyles';
import { TooltipClasses } from './components/modal/tooltipStyles';
import { MyAccountMiniContainerClasses } from '../../common/components/myAccountDxp/myAccountMiniContainerStyles';
import { BackToFinanceButtonClasses } from '../common/components/steps/backToFinanceButton/backToFinanceButtonStyles';
import { PrimaryButtonClasses } from './components/buttons/primaryButton/primaryButtonStyles';
import { SecondaryButtonClasses } from './components/buttons/secondaryButton/secondaryButtonStyles';
import { MyAccountChangeDetailsCtaClasses } from '../commonSteps/enquiryForm/components/enquiryForm/myAccountChangeDetailsCtaStyles';
import { SaveMyBuildModalClasses } from './components/saveMyBuild/saveMyBuildModalStyles';
import { IndicativeNumberClasses } from './components/buttons/indicativeNumber/indicativeNumberStyles';
import { ContentRowStylesClasses } from './components/carOptionsSummary/contentRow/contentRowStyles';
import { CheckoutConfirmationScreenClasses } from './components/confirmation/checkoutConfirmationScreenStyles';
import { CarConfirmationDetailsClasses } from './components/confirmation/orderJourney/carConfirmationDetailsStyle';
import { ExtrasClasses } from './components/extras/components/extrasStyles';
import { AccesoriesClasses } from './components/accesories/accesoriesStyles';
import { DealerContainerClasses } from './components/dealer/dealerContainerStyles';
import { DealerSummaryClasses } from './components/dealer/dealerSummary/dealerSummaryStyles';
import { VehiclePriceClasses } from './components/carOptionsSummary/vehiclePrice/vehiclePriceStyle';
import { ChangeActiveStepButtonClasses } from './components/buttons/changeActiveStep/changeActiveStepButtonStyles';
import { MySummaryContainerClasses } from './components/summary/mySummaryContainerStyles';
import { CarDetailsWithFinanceClasses } from './components/summary/carDetails/CarDetailsPrices/carDetailsPricesWithFinanceStyles';
import { FinanceQuotePricesStylesClasses } from './components/summary/prices/finance/financeQuotePricesStyles';
import { ExpressionsOfInterestWrapperClasses } from './components/common/salesSummary/expressionsOfInterestSummary/expressionsOfInterestWrapperStyles';
import { ExpressionOfInterestSummaryClasses } from './components/extras/components/expressionOfInterestSummary/expressionOfInterestSummaryStyles';
import { ExpressionsOfInterestClasses } from './components/common/expressionsOfInterest/expressionsOfInterestStyles';
import { ExpressionItemClasses } from './components/common/expressionsOfInterest/expressionItenStyles';
import { ExpandableSummaryClasses } from './components/sidebarControls/expandableSummaryStyles';
import { SectionAccordionStylesClasses } from './components/carOptionsSummary/sectionAccordion/sectionAccordionStyles';
import { SummaryExtendedViewClasses } from './components/summary/summaryViews/summaryExtendedViewStyles';
import { FinanceSummaryStylesClasses } from './components/common/salesSummary/financeSummary/financeSummeryStyles';
import { VehiclePriceCheckoutClasses } from './components/carOptionsSummary/vehiclePrice/vehiclePriceCheckoutStyles';
import { SelectionsCheckoutClasses } from './components/carOptionsSummary/selections/selectionsCheckoutStyles';
import { VehicleTotalCheckoutClasses } from './components/carOptionsSummary/vehicleTotalPrice/vehicleTotalCheckoutStyles';
import { ExpandableSummaryCheckoutClasses } from './components/sidebarControls/expandableSummaryCheckoutStyles';
import { FinanceSummeryCheckoutClasses } from './components/common/salesSummary/financeSummary/financeSummeryCheckoutStyles';
import { TaxSummaryCheckoutClasses } from './components/carOptionsSummary/tax/taxSummaryCheckoutStyles';
import { SummaryStepContainerClasses } from './components/summaryStepContainer/summaryStepContainerStyles';
import { ConnectWithMyAccountClasses } from './components/confirmation/orderJourney/connectWithMyAccountStyles';
import { ResponsiveSidebarClasses } from './components/sidebarControls/responsiveSidebar/responsiveSidebarStyles';
import { SalesSummaryClasses } from './components/common/salesSummary/salesSummaryStyles';
import { InsuranceSummaryClasses } from './components/carOptionsSummary/insuranceSummaryStyles';
import { TaxSummaryStylesClasses } from './components/carOptionsSummary/tax/taxSummaryStyles';
import { ConfirmationPageClasses } from './components/saveMyBuild/components/confirmationPage/confirmationPageStyles';
import { Checkout4LiteMainRoutesContainerClasses } from './components/checkout4LiteMainRoutesContainerStyles';
import { ReservationFeeClasses } from './components/common/salesSummary/reservationFee/reservationFeeStyles';
import { GovernmentEVRebateClasses } from './components/carOptionsSummary/GovernmentEVRebate/GovernmentEVRebateStyles';
import { TotalPurchaseAmountClasses } from './components/carOptionsSummary/totalPurchaseAmount/totalPurchaseAmountStyles';
import { HeadlessFinanceSectionClasses } from './components/headlessFinance/headlessFinanceSectionStyles';
import { InStorePaymentClasses } from './components/payment/inStorePaymentStyles';
import { PaymentPageClasses } from './components/payment/paymentPageStyles';
import { PaymentDisclaimerModalClasses } from './components/payment/paymentDisclaimerModal/paymentDisclaimerModalStyles';
import { CoTwoEmissionsClasses } from './components/carOptionsSummary/coTwoEmissions/coTwoEmissionsStyles';
import { CoTwoEmissionsCheckoutClasses } from './components/carOptionsSummary/coTwoEmissions/coTwoEmissionsCheckoutStyles';
import { ReservationFeeCheckoutClasses } from './components/common/salesSummary/reservationFee/reservationFeeCheckoutStyles';
import { DiscountSummaryCheckoutClasses } from './components/carOptionsSummary/discounts/discountSummaryCheckoutStyles';
import { OptionSummaryCheckoutClasses } from './components/carOptionsSummary/selections/accessoriesOptions/optionSummaryCheckoutStyles';
import { BundlesSummaryStylesClasses } from './components/carOptionsSummary/selections/allInclusive/bundlesSummaryStyles';
import { SoftLockModalClasses } from './components/softLockModal/softLockModalStyles';
import { OrderFeeClasses } from './components/common/salesSummary/orderFee/orderFeeStyles';

export interface ICheckout4ThemableComponents {
  Checkout4_Navigation: IThemableComponent<NavigationClasses>;
  Checkout4_BaseConfigurator: IThemableComponent<CheckoutLayoutClasses>;
  Checkout4_Banner: IThemableComponent<BannerStylesClasses>;
  Checkout4_ContinueButton: IThemableComponent<ContinueButtonClasses>;
  Checkout4_JourneySteps: IThemableComponent<JourneyStepsClasses>;
  Checkout4_JourneyList: IThemableComponent<JourneyListClasses>;
  Checkout4_JourneySummary: IThemableComponent<JourneySummaryClasses>;
  Checkout4_MyAccountMiniContainer: IThemableComponent<MyAccountMiniContainerClasses>;
  Checkout4_FinanceAndInsurance: IThemableComponent<FinanceAndInsuranceClasses>;
  Checkout4_FinanceSection: IThemableComponent<FinanceSectionClasses>;
  Checkout4_PaymentDisclaimer: IThemableComponent<PaymentDisclaimerClasses>;
  Checkout4_PaymentDisclaimerModal: IThemableComponent<PaymentDisclaimerModalClasses>;
  Checkout4_PaymentSummaryClasses: IThemableComponent<PaymentSummaryClasses>;
  Checkout4_SidebarControlsLayout: IThemableComponent<SidebarControlsLayoutClasses>;
  Checkout4_ResponsiveSidebar: IThemableComponent<ResponsiveSidebarClasses>;
  Checkout4_SidebarCarDetails: IThemableComponent<CarDetailsClasses>;
  Checkout4_OfferSummary: IThemableComponent<OfferSummaryClasses>;
  Checkout4_DiscountSummary: IThemableComponent<DiscountSummaryStylesClasses>;
  Checkout4_DiscountSummaryCheckout: IThemableComponent<DiscountSummaryCheckoutClasses>;
  Checkout4_OptionSummary: IThemableComponent<OptionSummaryStylesClasses>;
  Checkout4_OptionSummaryCheckout: IThemableComponent<OptionSummaryCheckoutClasses>;
  Checkout4_FullModelName: IThemableComponent<FullModelNameClasses>;
  Checkout4_VehicleTotal: IThemableComponent<VehicleTotalClasses>;
  Checkout4_Selections: IThemableComponent<SelectionsClasses>;
  Checkout4_TradeInButton: IThemableComponent<TradeInButtonStylesClasses>;
  Checkout4_UpdateOrderButton: IThemableComponent<UpdateOrderButtonClasses>;
  Checkout4_SaveMyBuildButton: IThemableComponent<SaveMyBuildButtonClasses>;
  Checkout4_FooterDisclaimer: IThemableComponent<DisclaimerClasses>;
  Checkout4_Tooltip: IThemableComponent<TooltipClasses>;
  Checkout4_PrimaryButton: IThemableComponent<PrimaryButtonClasses>;
  Checkout4_SecondaryButton: IThemableComponent<SecondaryButtonClasses>;
  Checkout4_ChangeDetailsCTA: IThemableComponent<MyAccountChangeDetailsCtaClasses>;
  Checkout4_SaveMyBuildModal: IThemableComponent<SaveMyBuildModalClasses>;
  Checkout4_IndicativeNumber: IThemableComponent<IndicativeNumberClasses>;
  Checkout4_ContentRowStyles: IThemableComponent<ContentRowStylesClasses>;
  Checkout4_CheckoutConfirmationScreen: IThemableComponent<CheckoutConfirmationScreenClasses>;
  Checkout4_CarConfirmationDetails: IThemableComponent<CarConfirmationDetailsClasses>;
  Checkout4_BackToFinanceButton: IThemableComponent<BackToFinanceButtonClasses>;
  Checkout4_Extras: IThemableComponent<ExtrasClasses>;
  Checkout4_DealerContainer: IThemableComponent<DealerContainerClasses>;
  Checkout4_DealerSummary: IThemableComponent<DealerSummaryClasses>;
  Checkout4_SummaryVehiclePrice: IThemableComponent<VehiclePriceClasses>;
  Checkout4_ChangeActiveStepButton: IThemableComponent<ChangeActiveStepButtonClasses>;
  Checkout4_mySummaryContainerStyles: IThemableComponent<MySummaryContainerClasses>;
  Checkout4_SummaryExpressionsOfInterest: IThemableComponent<ExpressionsOfInterestClasses>;
  Checkout4_SummaryExpressionItem: IThemableComponent<ExpressionItemClasses>;
  Checkout4_SummaryCarDetailsWithFinance: IThemableComponent<CarDetailsWithFinanceClasses>;
  Checkout4_SummaryFinanceQuotePricesMulti: IThemableComponent<FinanceQuotePricesStylesClasses>;
  Checkout4_ExpressionsOfInterestWrapper: IThemableComponent<ExpressionsOfInterestWrapperClasses>;
  GqlCheckout_ExpressionOfInterestSummary: IThemableComponent<ExpressionOfInterestSummaryClasses>;
  Checkout4_expandableSummaryStyles: IThemableComponent<ExpandableSummaryClasses>;
  Checkout4_SectionAccordion: IThemableComponent<SectionAccordionStylesClasses>;
  Checkout4_SummaryExtendedView: IThemableComponent<SummaryExtendedViewClasses>;
  Checkout4_FinanceSummary: IThemableComponent<FinanceSummaryStylesClasses>;
  Checkout4_SummaryVehiclePriceCheckout: IThemableComponent<VehiclePriceCheckoutClasses>;
  Checkout4_SelectionsCheckout: IThemableComponent<SelectionsCheckoutClasses>;
  Checkout4_VehicleTotalCheckout: IThemableComponent<VehicleTotalCheckoutClasses>;
  Checkout4_ExpandableSummaryCheckout: IThemableComponent<ExpandableSummaryCheckoutClasses>;
  Checkout4_FinanceSummeryCheckout: IThemableComponent<FinanceSummeryCheckoutClasses>;
  Checkout4_TaxSummary: IThemableComponent<TaxSummaryStylesClasses>;
  Checkout4_TaxSummaryCheckout: IThemableComponent<TaxSummaryCheckoutClasses>;
  Checkout4_SummaryStepContainer: IThemableComponent<SummaryStepContainerClasses>;
  Checkout4_ConnectMyAccount: IThemableComponent<ConnectWithMyAccountClasses>;
  Checkout4_SalesSummary: IThemableComponent<SalesSummaryClasses>;
  Checkout4_InsuranceSummary: IThemableComponent<InsuranceSummaryClasses>;
  Checkout4_ConfirmationPage: IThemableComponent<ConfirmationPageClasses>;
  Checkout4_Accesories: IThemableComponent<AccesoriesClasses>;
  Checkout4_checkoutLiteMainRoutesContainer: IThemableComponent<Checkout4LiteMainRoutesContainerClasses>;
  Checkout4_ReservationFee: IThemableComponent<ReservationFeeClasses>;
  Checkout4_ReservationFeeCheckout: IThemableComponent<ReservationFeeCheckoutClasses>;
  Checkout4_GovernmentEVRebate: IThemableComponent<GovernmentEVRebateClasses>;
  Checkout4_TotalPurchaseAmount: IThemableComponent<TotalPurchaseAmountClasses>;
  Checkout4_HeadlessFinanceSection: IThemableComponent<HeadlessFinanceSectionClasses>;
  Checkout4_InStorePayment: IThemableComponent<InStorePaymentClasses>;
  Checkout4_PaymentPage: IThemableComponent<PaymentPageClasses>;
  Checkout4_CoTwoEmissions: IThemableComponent<CoTwoEmissionsClasses>;
  Checkout4_CoTwoEmissionsCheckout: IThemableComponent<CoTwoEmissionsCheckoutClasses>;
  Checkout4_BundlesSummary: IThemableComponent<BundlesSummaryStylesClasses>;
  Checkout4_SoftLockModal: IThemableComponent<SoftLockModalClasses>;
  Checkout4_OrderFee: IThemableComponent<OrderFeeClasses>;
}

const GqlDeferredCheckout: UniversalComponent<any> = universal<any>(() => import('./components/CheckoutScreen'), {
  loading: (): JSX.Element => <MinimalLoader />,
  error: (props: { error: Error }) => {
    errorHandler.handleError(props.error);
    return <ThemedErrorMessage fullPage={true} message={'Failed loading checkout screen.'} error={props.error} />;
  },
});

export default GqlDeferredCheckout;
