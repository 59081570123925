import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../common/theme/ICheckoutTheme';

export type SoftLockModalClasses =
  | 'root'
  | 'buttonContainer'
  | 'button'
  | 'message'
  | 'additionalMessage'
  | 'softLockContinueBtn';

const softLockModalStyles = makeStyles<ICheckoutTheme, SoftLockModalClasses>(
  ({ typography }) => ({
    root: {
      display: 'grid',
      fontFamily: typography.fontFamily,
      padding: '16px',
    },
    buttonContainer: {
      fontFamily: typography.fontFamily,
      maxWidth: '100%',
      textAlign: 'center',
    },
    button: {
      margin: '0 5px 0 5px',
    },
    message: {
      margin: 'auto',
      padding: '10px 0',
    },
    softLockContinueBtn: {},
    additionalMessage: {},
  }),
  { name: 'Checkout4_SoftLockModal' },
);

export default softLockModalStyles;
