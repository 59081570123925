import React, { FunctionComponent, memo, useCallback, useEffect } from 'react';
import FinanceCalculatorWidget from './FinanceCalculatorWidget';
import ModalWithHeader from '../../../common/components/presentation/ModalWithHeader/ModalWithHeader';
import financeCalculatorContainerStyles from './financeCalculatorContainerStyles';
import Translation from '../../../common/components/presentation/Translation/Translation';
import { customEvent } from '../../../common/service/customEvent';
import CalculatorEvents from '../../../common/enum/CalculatorEvents';

interface IFinanceCalculatorContainerProps {
  isModalOpen: boolean;
  toggleModal?: (shouldOpen: boolean) => void;
  carConfiguration?: string;
}

const FinanceCalculatorContainer: FunctionComponent<IFinanceCalculatorContainerProps> = memo(
  ({ isModalOpen, toggleModal, carConfiguration }) => {
    const styles = financeCalculatorContainerStyles();

    {
      /* @Todo add test for this scenario */
    }
    const receiveFinCalcResponse = useCallback(({ detail }) => {
      if (detail) {
        toggleModal(false);
      }
    }, []);

    useEffect(() => {
      customEvent.listen(CalculatorEvents.CONTINUE, receiveFinCalcResponse);

      return () => {
        customEvent.removeListener(CalculatorEvents.CONTINUE, receiveFinCalcResponse);
      };
    }, []);

    return (
      <ModalWithHeader
        open={isModalOpen}
        toggleModal={toggleModal}
        title={<Translation id="trans__checkout__next_step__payment_method_section_label" />}
        titleProps={{
          'data-test': 'checkout:title:finCalc-title',
          id: 'checkout:finCalc-title',
        }}
        contentProps={{
          'data-test': 'checkout:finCalc-container',
        }}
        className={styles.finCalc}
      >
        {carConfiguration && <FinanceCalculatorWidget carConfiguration={carConfiguration} />}
      </ModalWithHeader>
    );
  },
);

export default FinanceCalculatorContainer;
