import { gql } from '@apollo/client/core';

const savePaymentForm = {
  SAVE_FORM: gql`
    mutation SavePaymentForm($data: SalesEnablementPaymentFormData) {
      salesEnablement {
        savePaymentForm(data: $data) {
          description
          referencedCode
          details {
            inStorePayment {
              inStoreDatePaid
              inStoreDescription
              inStorePaymentAmount
              inStoreReferenceNumber
            }
          }
        }
      }
    }
  `,
};

export default savePaymentForm;
