import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useDealers } from '../../../../commonSteps/dealer/hooks/useDealers';
import dealerSummaryStyles from './dealerSummaryStyles';
import useCheckoutContext from '../../../../common/hooks/useCheckoutContext';
import DealerPageInfo from '../../../../commonSteps/dealer/components/dealerPageInfo/DealerPageInfo';

const DealerSummary: FunctionComponent = () => {
  const { deal } = useCheckoutContext();
  const dealerId = deal?.dealer?.externalId;
  const { data } = useDealers();
  const dealers = data?.dealers?.nodes;
  const currentDealer = dealers?.find(({ externalId }) => externalId === dealerId);
  const styles = dealerSummaryStyles();
  const { isStock } = useCheckoutContext();

  if (!currentDealer) {
    return null;
  }

  return (
    <Box className={styles.dealerSummary} data-test="checkout:dealer:summary">
      {isStock && <DealerPageInfo />}
      <Typography variant="subtitle2" className={styles.dealerName} data-test="checkout:dealer:name">
        {currentDealer.label}
      </Typography>
      <Box className={styles.dealerAddress}>
        <Typography
          className={styles.dealerItem}
          variant="body2"
          component="div"
          data-test="checkout:dealer:streetName"
        >
          {currentDealer.address?.address}
        </Typography>
        <Typography className={styles.dealerItem} variant="body2" component="div" data-test="checkout:dealer:city">
          {currentDealer.address?.city}
        </Typography>
      </Box>
    </Box>
  );
};

export default DealerSummary;
