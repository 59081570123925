import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type FailedPaymentErrorClasses = 'icon' | 'subTitle' | 'email' | 'description' | 'container';

const failedPaymentErrorStyles = makeStyles<ICheckoutTheme, FailedPaymentErrorClasses>(
  () => ({
    icon: {},
    subTitle: {
      marginBottom: '25px',
    },
    email: {},
    description: {},
    container: {
      position: 'relative',
    },
  }),
  { name: 'Gql_FailedPaymentError' },
);

export default failedPaymentErrorStyles;
