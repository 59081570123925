import React, { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from 'react';
import { IOption, ISchemaFieldProps } from '../../model/IJsonSchema';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { InputAdornment } from '@material-ui/core';
import GenericInput from '../../../../common/components/genericInput/GenericInput';
import genericInputStyles from '../../../../common/components/genericInput/GenericInputStyles';
import { KeyboardArrowDown } from '@material-ui/icons';
import usePlatformConfig from '../../../../../../../checkout_lite/src/common/react/usePlatformConfig';

const AutocompleteInput: FunctionComponent<ISchemaFieldProps> = ({ formData }) => {
  const { label, required, id, value, options, onChange, placeholder, schema } = formData;
  const styles = genericInputStyles();
  const [dropdownOpened, setDropdownOpen] = useState(false);
  const defaultValue = options.enumOptions.find(option => option.value === value);
  const filterOptions = createFilterOptions<IOption>({
    matchFrom: 'any',
    limit: 50,
  });

  const {
    platformConfig: { checkout },
  } = usePlatformConfig();

  const { showDropdownIcon } = checkout || {};

  const handleOnChange = (option: IOption) => {
    onChange(option?.value);
    setDropdownOpen(false);
  };

  const handleBodyScroll = () => {
    if (dropdownOpened) {
      setDropdownOpen(false);
    }
  };

  const autocompleteRef = useRef<HTMLDivElement | null>(null);

  const handleCloseDropdownOnPageClick = (event: MouseEvent) => {
    if (autocompleteRef.current && !autocompleteRef.current.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    const body = document.body;
    const scrollbarWidth = window.innerWidth - body.clientWidth;

    body.style.overflow = dropdownOpened ? 'hidden' : 'auto';
    body.style.paddingRight = dropdownOpened ? `${scrollbarWidth}px` : 'unset';
    body.addEventListener('scroll', handleBodyScroll);
    document.addEventListener('click', handleCloseDropdownOnPageClick);

    return () => {
      body.style.overflow = 'auto';
      body.style.paddingRight = 'unset';
      body.removeEventListener('scroll', handleBodyScroll);
      document.removeEventListener('click', handleCloseDropdownOnPageClick);
    };
  }, [dropdownOpened]);

  return (
    <div ref={autocompleteRef}>
      <Autocomplete
        open={dropdownOpened}
        filterOptions={filterOptions}
        noOptionsText={options.invalidOption}
        onChange={(_: ChangeEvent<{ value?: string }>, option: IOption) => handleOnChange(option)}
        onOpen={() => !schema?.readOnly && setDropdownOpen(true)}
        onClose={() => setDropdownOpen(false)}
        classes={{
          input: styles.input,
          noOptions: styles.noOptions,
          listbox: styles.listbox,
          option: styles.option,
          paper: styles.paper,
        }}
        id={id}
        options={options.enumOptions}
        getOptionLabel={option => option.label}
        disableClearable={schema?.readOnly}
        defaultValue={defaultValue}
        data-test={`autocomplete_input:${id}`}
        renderInput={params => {
          const inputProps = {
            label,
            required,
            id,
            value,
            params,
            placeholder,
            tooltip: options.tooltip,
            readOnly: schema?.readOnly,
            endAdornment: (
              <InputAdornment position="end">
                {showDropdownIcon && (
                  <KeyboardArrowDown onClick={() => setDropdownOpen(true)} className={styles.arrowDownIconButton} />
                )}
              </InputAdornment>
            ),
          };
          if (options.displayKeyOnSelect && !dropdownOpened) {
            params.inputProps = { ...params.inputProps, value };
            return <GenericInput {...inputProps} />;
          }

          return <GenericInput {...inputProps} />;
        }}
      />
    </div>
  );
};

export default AutocompleteInput;
