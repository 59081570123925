import { useCallback } from 'react';
import { IMoney } from '../../../../../../common/model/IMoney';
import usePlatformConfig from '../../../../common/react/usePlatformConfig';

const useMoney = (prices: IMoney[]) => {
  const {
    platformConfig: { priceDisplay },
  } = usePlatformConfig();
  const isPrimaryPrice = useCallback(price => price?.currency === priceDisplay.primary.currency, [priceDisplay]);
  const isSecondaryPrice = useCallback(price => price?.currency === priceDisplay.secondary?.currency, [priceDisplay]);

  const getPrimaryPrice = useCallback((prices: IMoney[]) => prices?.find(isPrimaryPrice), [priceDisplay]);
  const getSecondaryPrice = useCallback((prices: IMoney[]) => prices?.find(isSecondaryPrice), [priceDisplay]);

  const primaryPrice = getPrimaryPrice(prices);
  const secondaryPrice = getSecondaryPrice(prices);

  return { primaryPrice, secondaryPrice };
};

export default useMoney;
