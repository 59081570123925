import { useContext } from 'react';
import JourneyStepsContext, { IJourneyStepsContextProps } from '../components/context/JourneyStepsContext';

const useJourneyStepsContext = (): IJourneyStepsContextProps => {
  const { steps, activeStep, changeActiveStep, handleNext, isFirstLoad, setIsFirstLoad } = useContext(
    JourneyStepsContext,
  );

  return { steps, activeStep, changeActiveStep, handleNext, isFirstLoad, setIsFirstLoad };
};

export default useJourneyStepsContext;
