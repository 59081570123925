import { currency } from '../service/currency';
import urlRoute from '../service/urlRoute';
import CONSTANTS from './constants';

const { BASE_URL, AUD, FINANCE_CALCULATOR_WIDGET_BASE_PATH } = CONSTANTS;

const fcConfig = {
  instanceId: 1,
  currency: currency.getCurrency(AUD),
  backendUrl: BASE_URL,
  widgetUrl: process.env.REACT_APP_WIDGETS_BASE_PATH
    ? urlRoute.joinUrl(process.env.REACT_APP_WIDGETS_BASE_PATH + FINANCE_CALCULATOR_WIDGET_BASE_PATH)
    : process.env.REACT_APP_FC_WIDGET_PATH,
  paths: {
    chunkPath: process.env.REACT_APP_WIDGETS_BASE_PATH ? '/modules.fc.chunk.js' : '/legacy-widget.fc.chunk.js',
    widgetPath: '/fc.js',
  },
  dxpWidgetUrl:
    process.env.REACT_APP_DXP_WIDGETS_BASE_PATH +
    `${process.env.REACT_APP_COUNTRY}/${process.env.REACT_APP_BRAND_CODE}/widgets/finance-calculator/dist/finance-calculator.esm.js`,
};

export default fcConfig;
