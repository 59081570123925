import { Typography } from '@material-ui/core';
import React, { FunctionComponent, memo } from 'react';
import clsx from 'clsx';
import { Variant } from '@material-ui/core/styles/createTypography';
import { PriceDisplayType } from '../../../../../../../common/enum/PriceDisplayType';
import usePlatformConfig from '../../../../../common/react/usePlatformConfig';
import usePriceFormatter from '../../../../../../../common/react/usePriceFormatter';
import useWidgetContext from '../../../../../common/react/useWidgetContext';

export interface IPriceProps {
  price: number;
  priceDisplayType: PriceDisplayType;
  currency: string;
  className?: string;
  variant?: Variant;
  dataTest?: string;
  taxLabel?: string;
}

const Price: FunctionComponent<IPriceProps & { component?: any }> = memo(
  ({ price, priceDisplayType, currency, className, variant, dataTest, taxLabel, ...rest }) => {
    const {
      configuration: {
        dimensions: { language, country },
      },
    } = useWidgetContext();
    const {
      platformConfig: { priceDisplay },
    } = usePlatformConfig();
    const { decimalScale = 0, subunitToUnit = 100, currencyFormatConfig } = priceDisplay[priceDisplayType];
    const priceFormatter = usePriceFormatter({
      currency,
      dimensions: {
        language,
        country,
      },
      decimals: decimalScale ?? 0,
      currencyFormatConfig,
    });

    if (!currency) {
      return;
    }

    return (
      <Typography
        {...rest}
        component="span"
        data-test={dataTest}
        variant={variant ?? 'subtitle1'}
        className={clsx(className)}
      >
        {priceFormatter.format(price / (subunitToUnit || 1))}
        {taxLabel && ` ${taxLabel}`}
      </Typography>
    );
  },
);

export default Price;
