import { ICheckoutTheme } from '../../../common/theme/ICheckoutTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

export type FinanceCalculatorContainerClasses = 'closeIcon' | 'finCalc';

const financeCalculatorContainerStyles = makeStyles<ICheckoutTheme, FinanceCalculatorContainerClasses>(
  theme => ({
    closeIcon: {
      cursor: 'pointer',
      width: '20px',
      height: '20px',
    },
    finCalc: {
      position: 'relative',
      borderBottomLeftRadius: 30,
      borderBottomRightRadius: 30,
      // Increase specificity.
      '&:not(:disabled)': {
        padding: 0,
        width: '100%',
        minHeight: '70vh',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '720px',
        minHeight: '50rem',
      },
      [theme.breakpoints.down('xs')]: {
        borderRadius: 0,
      },
    },
  }),
  { name: 'Common_FinanceCalculatorContainer' },
);

export default financeCalculatorContainerStyles;
