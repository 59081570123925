import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Box, List, ListItem, ListItemAvatar, ListItemText, Radio, Typography } from '@material-ui/core';
import { ApolloError, useReactiveVar } from '@apollo/client';
import Translation from '../../../../common/components/presentation/Translation/Translation';
import useUrlDealId from '../../../common/hooks/useUrlDealId';
import { activeJourneyType } from '../../../common/graphql/cache';
import useCheckoutContext from '../../../common/hooks/useCheckoutContext';
import { useSelectJourney } from '../../hooks/mutations/useSelectJourneyType';
import journeyListStyles from './journeyListStyles';
import { JourneyType, StockType } from '../../../../__generated__/globalTypes';
import { ScrollInViewBehaviourType, ScrollInViewBlockType } from '../../../../common/enum/ScrollInViewTypes';
import ContinueButton from '../buttons/continueButton/ContinueButton';
import useJourneyStepsContext from '../../hooks/useJourneyStepsContext';
import useStockApiPing from '../../../common/hooks/queries/useStockApiPing';
import usePlatformConfig from '../../../../common/react/usePlatformConfig';
import SoftLockModal from '../../../checkout4/components/softLockModal/softLockModal';
import errorHandler from '../../../../gql/common/service/errorHandler';
import { usePreOrderOnlineExternal } from '../../hooks/queries/usePreOrderOnlineExternal';
import useJourneyListContext from '../../context/journeys/useJourneyListContext';
import useHasJourney from '../../hooks/useHasJourney';
import { JourneyStepsRoutes } from '../../enum/JourneyStepsRoutes';
import useCallDigitalAssignmentEntryEvent from '../../../common/hooks/dataLayer/useCallDigitalAssignmentEntryEvent';
import useWidgetContext from '../../../../common/react/useWidgetContext';
import dataLayerToggleVerification from '../../../../../../common/utilities/dataLayerToggleVerification';
import useSalesEnablementContext from '../../../../gql/common/hooks/useSalesEnablementContext';
import { DealStatus } from '../../enum/DealStatus';

const JourneyList: FunctionComponent = () => {
  const {
    platformConfig: {
      checkout,
      platformFeatures: { isReservationJourneyOptional, isDisabledReservationJourneyLabel, hasOptionalJourneys },
    },
  } = usePlatformConfig();

  const {
    hideJourneySelection,
    hideSelectJourneyContinueButton,
    hideDefaultJourneyHighlight,
    skipJourneyStepOnSalesEnablement,
  } = checkout || {};
  const styles = journeyListStyles();
  const { isOrderPlacementDisabled } = useStockApiPing();
  const { isInSalesEnablement } = useSalesEnablementContext();
  const [softLocked, setSoftLocked] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hideDefaultJourneyOnFirstStep, setHideDefaultJourneyOnFirstStep] = useState<boolean>(
    hideDefaultJourneyHighlight,
  );
  const dealId = useUrlDealId();
  const { setDeal, deal } = useCheckoutContext();
  const { selectJourney } = useSelectJourney();
  const { steps, handleNext, changeActiveStep, isFirstLoad, setIsFirstLoad } = useJourneyStepsContext();
  const { journeyList, updateJourneyList } = useJourneyListContext();
  const userJourney = useReactiveVar(activeJourneyType);
  const { hasJourney } = useHasJourney();
  const hasPreOrderJourney = hasJourney(JourneyType.pre_order_now);
  const listRef = useRef(null);
  const { postCode } = deal?.dealer || {};
  const { stockType } = deal?.car.stock || {};
  const {
    fetchPreOrderOnlineExternal,
    data: preOrderExternalLink,
    error: errorPreOrder,
    loading: loadingPreOrder,
  } = usePreOrderOnlineExternal(dealId, postCode);
  const skipJourneySelectionOnSingleJourney = hideSelectJourneyContinueButton && journeyList.length == 1;
  const [handleJourneyList, setHandleJourneyList] = useState(journeyList);
  const {
    configuration: { dimensions },
  } = useWidgetContext();
  const { callDigitalAssignmentEntryEvent } = useCallDigitalAssignmentEntryEvent();

  useEffect(() => {
    setIsNextDisabled(userJourney === JourneyType.save_my_build);
    if (hideSelectJourneyContinueButton) {
      setIsNextDisabled(false);
    }
  }, [userJourney]);

  useEffect(() => {
    if (hasPreOrderJourney) {
      fetchPreOrderOnlineExternal();
    }
  }, []);

  useEffect(() => {
    if (userJourney === JourneyType.pre_order_now) {
      setIsNextDisabled(loadingPreOrder || !!errorPreOrder || !preOrderExternalLink?.preOrderOnlineExternal);
      setIsLoading(loadingPreOrder);
    }
  }, [userJourney, loadingPreOrder, errorPreOrder, preOrderExternalLink]);

  const onClickHandler = (journeyType: JourneyType) => {
    if (!isLoading) {
      if (handleListItemDisable(journeyType)) return;
      activeJourneyType(journeyType);
      if (hideSelectJourneyContinueButton && !isNextDisabled) {
        setHideDefaultJourneyOnFirstStep(false);
        handleNextClick();
      } else {
        listRef.current.scrollIntoView({
          behavior: ScrollInViewBehaviourType.SMOOTH,
          block: ScrollInViewBlockType.CENTER,
        });
      }
    }
  };

  useEffect(() => {
    if (isInSalesEnablement && skipJourneyStepOnSalesEnablement && isFirstLoad) {
      const isFailedStatus = deal?.status === DealStatus.FAILED;
      const isAuthorizedStatus = deal?.status === DealStatus.AUTHORIZED;
      setIsFirstLoad(false);
      if (!isFailedStatus && !isAuthorizedStatus) {
        activeJourneyType(JourneyType.continue_in_dealership);
        handleNextClick();
      } else {
        handleNext();
      }
    } else if (hideJourneySelection || skipJourneySelectionOnSingleJourney) {
      handleNext();
    }
  }, []);

  const handleNextStep = useCallback(() => {
    if (stockType === StockType.dealer) {
      const dealerStep = steps.findIndex(step => step.pathname === JourneyStepsRoutes.DEALER);

      //jump directly to Customer Information since Dealer cannot be changed
      changeActiveStep(dealerStep + 1);
    } else {
      handleNext();
    }
  }, [stockType, steps, changeActiveStep, handleNext]);

  const handleNextClick = useCallback(async () => {
    setIsNextDisabled(true);
    setIsLoading(true);
    const journeyType = activeJourneyType();

    const isDataLayer = dataLayerToggleVerification(dimensions);
    if (isDataLayer && journeyType === JourneyType.online_order) {
      callDigitalAssignmentEntryEvent({ deal, dimensions });
    }

    if (journeyType === JourneyType.pre_order_now && preOrderExternalLink?.preOrderOnlineExternal) {
      window.location.replace(preOrderExternalLink.preOrderOnlineExternal);
      return;
    }

    try {
      const data = await selectJourney(dealId, journeyType as JourneyType);
      setDeal(data.selectJourneyType);
      handleNextStep();
    } catch (e) {
      const setSoftLockedError = errorHandler.handleSoftLock(e as ApolloError);
      setSoftLocked(setSoftLockedError?.isStockLocked);
    } finally {
      setIsNextDisabled(false);
      setIsLoading(false);
    }
  }, [dealId, preOrderExternalLink]);

  const handleListItemDisable = (journeyType: JourneyType) =>
    (journeyType === JourneyType.online_order && isOrderPlacementDisabled) ||
    (journeyType === JourneyType.reserve_online && isOrderPlacementDisabled) ||
    (journeyType === JourneyType.pre_order_now && !!errorPreOrder);

  const handleListItemText = (description: string, journeyType: JourneyType) => {
    if (isDisabledReservationJourneyLabel && journeyType === JourneyType.reserve_online) {
      return handleListItemDisable(journeyType)
        ? 'trans__checkout__next_step__reserve_your_car_description'
        : description;
    }
    return handleListItemDisable(journeyType) ? 'trans__checkout__next_step__order_now_eod_message' : description;
  };

  const handleSoftLockContinueBtn = () => {
    updateJourneyList(JourneyType.online_order);
    setSoftLocked(false);
    activeJourneyType(JourneyType.continue_in_dealership);
  };

  useEffect(() => {
    if (isReservationJourneyOptional && !deal?.car.isForReservation) {
      setHandleJourneyList(journeyList.filter(journey => journey.journeyType !== JourneyType.reserve_online));
    } else {
      setHandleJourneyList(journeyList);
    }

    if (hasOptionalJourneys) {
      if (stockType === StockType.dealer) {
        setHandleJourneyList(journeyList.filter(journey => journey.journeyType !== JourneyType.pre_order_now));
      } else {
        setHandleJourneyList(journeyList.filter(journey => journey.journeyType !== JourneyType.online_order));
      }
    }
  }, [deal, journeyList]);

  const showPreOrderError = userJourney === JourneyType.pre_order_now && errorPreOrder;

  return (
    <>
      <List className={styles.listContainer} ref={listRef} data-test="checkout:journey_list">
        {handleJourneyList?.map((item, i) => (
          <ListItem
            className={clsx([
              styles.listItem,
              { [styles.selected]: userJourney === item.journeyType && !hideDefaultJourneyOnFirstStep },
            ])}
            key={i}
            onClick={() => onClickHandler(item.journeyType)}
            data-test={`checkout:journey:${item.journeyType}`}
            disabled={
              handleListItemDisable(item.journeyType) ||
              (hideSelectJourneyContinueButton && isNextDisabled) ||
              isLoading
            }
          >
            <ListItemAvatar
              className={clsx(styles.icon, {
                [styles.selectedIcon]: userJourney === item.journeyType && !hideDefaultJourneyOnFirstStep,
              })}
            >
              {item.icon}
            </ListItemAvatar>
            <ListItemText
              primary={<Translation className={styles.title} id={item.title} />}
              secondary={
                <Translation
                  className={styles.description}
                  id={handleListItemText(item.description, item.journeyType)}
                />
              }
              data-test="checkout:journey:details"
            />
            <Radio
              classes={{
                root: styles.radio,
                checked: styles.checked,
              }}
              className={styles.radioIcon}
              checked={userJourney === item.journeyType && !hideDefaultJourneyOnFirstStep}
              name={item.journeyType}
              disabled={isLoading}
            />
          </ListItem>
        ))}
      </List>
      {showPreOrderError && (
        <Typography variant="body2" className={styles.error} data-test="checkout:journey:pre-order:error">
          <Translation id="trans__checkout__errors__pre_order_journey_error" />
        </Typography>
      )}
      <Box className={styles.buttonWrapper}>
        {!hideSelectJourneyContinueButton && (
          <ContinueButton
            testId="checkout:continue_button:select_journey"
            activateNextStep={false}
            disabled={isNextDisabled}
            handleClick={handleNextClick}
            loading={isLoading}
          />
        )}
      </Box>
      <SoftLockModal
        isOpen={softLocked}
        handleContinueBtn={handleSoftLockContinueBtn}
        onClose={() => setSoftLocked(false)}
      />
    </>
  );
};

export default JourneyList;
