import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ISchemaError } from '../model/IJsonSchema';
import useForceUpdate from '../../../../common/hooks/useForceUpdate';

export type FormDataType = Record<string, any> | null;

const useCustomErrorMessagesMapper = (formData: FormDataType) => {
  const intl = useIntl();
  const { forceUpdate } = useForceUpdate();

  const getErrorDomain = (errorPath: string) => {
    const pathArray: string[] = errorPath.split('.');

    return pathArray[pathArray.length - 1];
  };

  const isFieldDirty = (domain: string, formData?: FormDataType): boolean => {
    if (!formData || typeof formData !== 'object') {
      return false;
    }

    if (domain in formData) {
      return !!formData[domain];
    }

    return Object.values(formData).some(value => {
      return isFieldDirty(domain, value);
    });
  };

  const filterDirtyFields = (errors: ISchemaError[], formData?: FormDataType) => {
    if (!formData) return errors;

    return errors.filter((error: ISchemaError) => {
      const domain = getErrorDomain(error.property);

      return isFieldDirty(domain, formData);
    });
  };

  useEffect(() => {
    forceUpdate();
  }, [formData]);

  return useCallback(
    (errors: ISchemaError[]) =>
      filterDirtyFields(errors, formData)?.map((error: ISchemaError) => {
        if (error.name === 'maximum') {
          error.message = intl.formatMessage({ id: 'trans__se__error_messages__in_store_error_message' });
        }

        return error;
      }),
    [formData, intl],
  );
};

export default useCustomErrorMessagesMapper;
