import { GetDeal, GetDealVariables } from '../../graphql/queries/__generated__/GetDeal';
import { useLazyQuery } from '@apollo/client';
import deal from '../../graphql/queries/deal';
import useErrorHandler from '../useErrorHandler';

const useGetDeal = (token: string, dataLayer?: boolean, fetchBreakdownJsonSchema?: boolean) => {
  const [loadDeal, { loading, error, data, refetch }] = useLazyQuery<GetDeal, GetDealVariables>(deal.GET_DEAL, {
    ...(token && {
      variables: { token, dataLayer: !!dataLayer, fetchBreakdownJsonSchema: fetchBreakdownJsonSchema ?? true },
    }),
    fetchPolicy: 'cache-and-network',
  });

  useErrorHandler(error);

  return { loadDeal, data, loading, error, refetch };
};

export default useGetDeal;
