import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from 'src/common/theme/ICheckoutTheme';

export type PaymentPageClasses = 'container';

const paymentPageStyles = makeStyles<ICheckoutTheme, PaymentPageClasses>(
  () => ({
    container: {},
  }),
  { name: 'Checkout4_PaymentPage' },
);

export default paymentPageStyles;
