import digitalDataAssignment from '../../../../../../common/utilities/digitalDataAssignment';
import IDimensions from '../../../../common/model/IDimensions';
import { GetDeal_deal } from '../../graphql/queries/__generated__/GetDeal';
import {
  DataLayerEvent,
  DataLayerFormName,
  DataLayerFormStage,
  DataLayerType,
} from '../../../../../../common/utilities/customDatalayerInterface';
import buildSelectedSpecData from '../../utils/dataLayer/buildSelectedSpecData';
import { FinanceResultType } from '../../../../__generated__/globalTypes';
import { customerStorage } from '../../../../../../common/service/customerStorage';
import hashUtilities from '../../../../../../common/utilities/hashUtilities';

type CallDigitalAssignmentEntryEventArgs = {
  deal: GetDeal_deal;
  dimensions: IDimensions;
  stage?: DataLayerFormStage;
};

const useCallDigitalAssignmentEntryEvent = () => {
  const callDigitalAssignmentEntryEvent = async ({ deal, dimensions, stage }: CallDigitalAssignmentEntryEventArgs) => {
    try {
      if (stage === DataLayerFormStage.EMAIL_MY_CONFIGURATION_SUBMITTED) {
        const { email } = deal.customer;
        const emailHashed = await hashUtilities.hashSHA256(email);

        digitalDataAssignment({
          event: DataLayerEvent.FORM_NAVIGATE,
          form: {
            name: DataLayerFormName.CONFIGURATOR,
            stage: DataLayerFormStage.EMAIL_MY_CONFIGURATION_SUBMITTED,
          },
          user: {
            emailHashed: emailHashed || null,
          },
        });

        return;
      }

      if (stage === DataLayerFormStage.PAYMENT_METHOD) {
        const { email, phone } = deal.customer;
        const emailHashed = await hashUtilities.hashSHA256(email);
        const phoneHashed = await hashUtilities.hashSHA256(phone);
        const { postcode } = customerStorage.getCustomerData(dimensions) || {};
        const dealer = deal?.dealer;

        digitalDataAssignment({
          event: DataLayerEvent.FORM_NAVIGATE,
          form: {
            name: DataLayerFormName.CONFIGURATOR_STOCK_RESERVATION,
            stage: DataLayerFormStage.PAYMENT_METHOD,
            details: {
              configurator: {
                financeOption: deal.finance.type as FinanceResultType,
                selectedSpec: buildSelectedSpecData({ deal, dimensions }),
              },
            },
          },
          user: {
            emailHashed: emailHashed || null,
            phoneHashed: phoneHashed || null,
            postCode: postcode || null,
            preferredDealership: dealer?.label || null,
          },
        } as DataLayerType);

        return;
      }

      digitalDataAssignment({
        event: DataLayerEvent.FORM_NAVIGATE,
        form: {
          name: DataLayerFormName.CONFIGURATOR_STOCK_RESERVATION,
          stage: DataLayerFormStage.START,
          details: {
            configurator: {
              selectedSpec: buildSelectedSpecData({ deal, dimensions }),
            },
          },
        },
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('DataLayer error', err);
    }
  };

  return { callDigitalAssignmentEntryEvent };
};

export default useCallDigitalAssignmentEntryEvent;
