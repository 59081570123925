import React, { useEffect, useState, useCallback } from 'react';
import { UrlParamTypes } from '../../../common/enum/UrlParamTypes';
import useGetDeal from '../hooks/queries/useGetDeal';
import { GetDeal_deal } from '../graphql/queries/__generated__/GetDeal';
import CheckoutContext from '../context/CheckoutContext';
import useGetExpressionOfInterest from '../hooks/queries/useGetExpressionOfInterest';
import { ExpressionOfInterest } from '../graphql/fragments/deal/__generated__/ExpressionOfInterest';
import { FinanceFormType, PaymentType, StockType } from '../../../__generated__/globalTypes';
import { ApolloError } from '@apollo/client';
import { GetExpressionOfInterest_expressionOfInterest } from '../graphql/queries/__generated__/GetExpressionOfInterest';
import { DiscountCodes } from '../../checkout4/enum/DiscountCodes';
import usePlatformConfig from '../../../common/react/usePlatformConfig';
import useSalesEnablementContext from '../hooks/useSalesEnablementContext';
import { MyAccountEvents } from '../../../common/enum/MyAccountEvents';
import { customEvent } from '../../../common/service/customEvent';
import { CustomerDetails } from '../graphql/fragments/__generated__/CustomerDetails';

interface IInitializerComponent<T, H = null> {
  hooks?: () => H;
  initialize: (args: H) => Promise<T>;
  component: React.ComponentType<T>;
}

const SessionDataProvider: IInitializerComponent<
  {
    deal?: GetDeal_deal;
    loading: boolean;
    error: ApolloError;
    setDeal?: (deal: GetDeal_deal) => void;
    refetch: () => void;
    expressionOfInterest: ExpressionOfInterest[];
    extrasPageHasContent: boolean | null;
    isStock: boolean;
    isJlrStockDiscount: boolean;
    stockType: StockType | null;
    paymentType?: PaymentType;
    setPaymentType?: (paymentType: PaymentType) => void;
    customerInformation?: CustomerDetails;
  },
  {
    deal?: GetDeal_deal;
    paymentType?: PaymentType;
    loading: boolean;
    error: ApolloError;
    setDeal?: (deal: GetDeal_deal) => void;
    setPaymentType?: (paymentType: PaymentType) => void;
    refetch: () => void;
    expressionOfInterest: ExpressionOfInterest[];
    customerInformation: CustomerDetails;
  }
> = {
  hooks: () => {
    const url = window.location.search;
    const searchParams = new URLSearchParams(url);
    const token = searchParams.get(UrlParamTypes.TOKEN) || '';
    const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.reservation_fee);
    const [deal, setDeal] = useState<GetDeal_deal | null>(null);
    const [customerInformation, setCustomerInformation] = useState<CustomerDetails>();
    const {
      platformConfig: {
        platformFeatures: { dataLayer },
        fetchBreakdownJsonSchema,
      },
    } = usePlatformConfig();
    const [expressionOfInterest, setExpressionOfInterest] = useState<GetExpressionOfInterest_expressionOfInterest[]>(
      null,
    );
    const { loadDeal, data, loading, error, refetch } = useGetDeal(token, dataLayer, fetchBreakdownJsonSchema);
    const { data: expressionOfInterestData, loading: eoiLoading } = useGetExpressionOfInterest();
    const { dealVersion } = useSalesEnablementContext();

    const loadDealQuery = useCallback(() => {
      loadDeal({
        variables: {
          token: token,
          dataLayer: !!dataLayer,
          fetchBreakdownJsonSchema: fetchBreakdownJsonSchema ?? true,
        },
      });
    }, [token]);

    useEffect(() => {
      loadDealQuery();
    }, []);

    useEffect(() => {
      if (dealVersion !== 0) {
        loadDealQuery();
      }
    }, [loadDealQuery, dealVersion]);

    useEffect(() => {
      if (data?.deal) {
        setDeal(data.deal);
      }
    }, [data]);

    useEffect(() => {
      if (!eoiLoading) {
        setExpressionOfInterest(expressionOfInterestData?.expressionOfInterest || []);
      }
    }, [expressionOfInterestData, eoiLoading]);

    const receiveMyAccountResponse = useCallback(
      ({ detail }) => {
        if (detail) {
          const parsedData = JSON.parse(detail);
          setCustomerInformation(parsedData.customer);
        }
      },
      [customerInformation],
    );
    useEffect(() => {
      customEvent.listen(MyAccountEvents.SEND_CUSTOMER_INFORMATION, receiveMyAccountResponse);

      return () => {
        customEvent.removeListener(MyAccountEvents.SEND_CUSTOMER_INFORMATION, receiveMyAccountResponse);
      };
    }, [receiveMyAccountResponse]);
    return {
      deal,
      loading,
      error,
      setDeal,
      refetch,
      expressionOfInterest,
      paymentType,
      setPaymentType,
      customerInformation,
    };
  },

  initialize: args => {
    const {
      deal,
      loading,
      error,
      setDeal,
      refetch,
      expressionOfInterest,
      paymentType,
      setPaymentType,
      customerInformation,
    } = args;
    const hasCash: boolean = deal?.finance?.type === FinanceFormType.cash;
    const extrasPageHasContent = deal && expressionOfInterest ? !!(hasCash || expressionOfInterest?.length) : null;
    const isStock = !!deal?.car?.stock;
    const isJlrStockDiscount = deal?.car?.prices?.discounts?.[0]?.code === DiscountCodes.JLR_STOCK;
    const stockType = deal?.car?.stock?.stockType || null;

    return Promise.resolve({
      deal,
      loading,
      error,
      setDeal,
      refetch,
      expressionOfInterest,
      extrasPageHasContent,
      isStock,
      isJlrStockDiscount,
      stockType,
      paymentType,
      setPaymentType,
      customerInformation,
    });
  },

  component: ({
    deal,
    loading,
    error,
    setDeal,
    refetch,
    expressionOfInterest,
    extrasPageHasContent,
    isStock,
    isJlrStockDiscount,
    stockType,
    children,
    paymentType,
    setPaymentType,
    customerInformation,
  }) => {
    return (
      <CheckoutContext.Provider
        value={{
          deal,
          loading,
          error,
          setDeal,
          refetch,
          expressionOfInterest,
          extrasPageHasContent,
          isStock,
          isJlrStockDiscount,
          stockType,
          paymentType,
          setPaymentType,
          customerInformation,
        }}
      >
        {children}
      </CheckoutContext.Provider>
    );
  },
};

export default SessionDataProvider;
