import React, { ReactElement } from 'react';
import InputField from './inputField/InputField';
import { ISchemaField } from '../model/IJsonSchema';
import DatePicker from '../../../common/components/dateField/DatePicker';
import TextareaAutosizeInput from './textareaAutosizeInput/TextareaAutosizeInput';
import PriceInput from './priceInput/PriceInput';

interface ICustomComponentsMapperReturn {
  PriceInput: (inputData: ISchemaField) => ReactElement;
  TextWidget: (inputData: ISchemaField) => ReactElement;
  DateWidget: (dateData: ISchemaField) => ReactElement;
  TextareaAutosizeWidget: (inputData: ISchemaField) => ReactElement;
}

type ICustomSchemaMapper = () => ICustomComponentsMapperReturn;

const CustomComponentsMapper: ICustomSchemaMapper = () => {
  return {
    PriceInput: (inputData: ISchemaField) => <PriceInput formData={inputData} />,
    TextWidget: (inputData: ISchemaField) => <InputField formData={inputData} />,
    DateWidget: (dateData: ISchemaField) => <DatePicker formData={dateData} />,
    TextareaAutosizeWidget: (inputData: ISchemaField) => <TextareaAutosizeInput formData={inputData} />,
  };
};

export default CustomComponentsMapper;
