import { gql } from '@apollo/client/core';

const customer = {
  CUSTOMER_DETAILS: gql`
    fragment CustomerDetails on Customer {
      salutation
      firstName
      lastName
      phone
      email
      identificationNumber
      marketingInformation
      attributes {
        preferredContactMethod
        areaCode
        areaCodeKey
        preferredDealer
        preferredDealerKey
      }
      companyName
      customerType
    }
  `,
};

export default customer;
