import React, { FunctionComponent, useCallback, useEffect, ChangeEvent } from 'react';
import { IRawError, ISchemaFieldProps } from '../../model/IJsonSchema';
import GenericInput from '../genericInput/GenericInput';

export interface IDatePickerInputProps {
  schemaField: ISchemaFieldProps;
  toggleDatePicker: (value: boolean) => void;
  errorMessage: IRawError;
}

const formattedDate = (value: string) => {
  return value?.replace(/\//g, '-');
};

const DatePickerInput: FunctionComponent<IDatePickerInputProps> = ({ schemaField, toggleDatePicker, errorMessage }) => {
  const {
    label,
    required,
    id,
    onChange,
    value,
    options: { inputLabel },
    placeholder,
  } = schemaField.formData;

  const propsValue = schemaField.props.value;

  useEffect(() => {
    onChange(formattedDate(propsValue));
  }, [propsValue]);

  const handleChange = useCallback(
    (e: ChangeEvent<{ value: string }>) => {
      onChange(formattedDate(e.target.value));
    },
    [value],
  );

  return (
    <GenericInput
      label={label}
      required={required}
      id={id}
      value={propsValue}
      currentError={errorMessage}
      onChange={handleChange}
      inputLabel={inputLabel}
      datePicker
      toggleDatePicker={toggleDatePicker}
      placeholder={placeholder}
      data-test={schemaField?.props?.inputProps?.['data-test']}
    />
  );
};

export default DatePickerInput;
