import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from 'src/common/theme/ICheckoutTheme';

export type InStorePaymentClasses = 'root' | 'label';

const inStorePaymentStyles = makeStyles<ICheckoutTheme, InStorePaymentClasses>(
  ({ typography }) => ({
    root: {},
    label: {
      fontSize: typography.fontMD,
      fontWeight: 600,
      lineHeight: '16px',
    },
  }),
  { name: 'Checkout4_InStorePayment' },
);

export default inStorePaymentStyles;
