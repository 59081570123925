import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ISchemaFieldProps } from '../../model/IJsonSchema';
import GenericInput from '../../../../common/components/genericInput/GenericInput';

export const InputFieldWithHyphen: FunctionComponent<ISchemaFieldProps> = ({ formData }) => {
  const { label, required, id, onChange, value, rawErrors, disabled, schema } = formData;
  const currentError = useMemo(() => rawErrors?.length > 0 && rawErrors[0], [rawErrors]);

  const formatString: (value: string) => string = value => {
    if (!value?.length) {
      return '';
    }

    const val = value.replace(/-/g, '');

    return val.substring(0, val.length - 1) + '-' + val.substring(val.length - 1);
  };

  const handleChange = useCallback(
    (e: React.ChangeEvent<{ value: string }>) => {
      const formattedString = formatString(e.target.value as string);

      onChange(formattedString);
    },
    [value],
  );

  const inputValue = value?.includes('-') ? value : formatString(value);

  return (
    <GenericInput
      label={label}
      required={required}
      id={id}
      onChange={handleChange}
      value={inputValue}
      currentError={currentError}
      disabled={disabled}
      readOnly={schema?.readOnly}
    />
  );
};

export default InputFieldWithHyphen;
