import { useContext } from 'react';
import CurrencyPreferenceContext, { ICurrencyPreferenceContextProps } from './CurrencyPreferenceContext';

const useCurrencyPreference = (): ICurrencyPreferenceContextProps => {
  const { isSecondaryCurrencyDisplay, setIsSecondaryCurrencyDisplay, displayedCurrency } = useContext(
    CurrencyPreferenceContext,
  );

  return { isSecondaryCurrencyDisplay, setIsSecondaryCurrencyDisplay, displayedCurrency };
};

export default useCurrencyPreference;
