import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import mercadoPagoContainerStyles from './mercadoPagoContainerStyles';
import { Typography } from '@material-ui/core';
import Translation from '../../../../../../common/components/presentation/Translation/Translation';

const MercadoPago: FunctionComponent = () => {
  const styles = mercadoPagoContainerStyles();
  const intl = useIntl();

  return (
    <>
      <Typography variant="subtitle1" className={styles.title}>
        <Translation id="trans__checkout__payment_page__subtitle" />
      </Typography>
      <Typography variant="subtitle2">
        <Translation className={styles.description} id="trans__checkout__payment_page__description1" />
      </Typography>
      <Typography variant="subtitle1" className={styles.description2}>
        <Translation
          id="trans__checkout__payment_page__description2"
          values={{
            email: (
              <a
                className={styles.email}
                href={`mailto:${intl.formatMessage({
                  id: 'trans__checkout__payment_page__email',
                })}`}
              >
                <Translation id={'trans__checkout__payment_page__email'} />
              </a>
            ),
          }}
        />
      </Typography>
    </>
  );
};

export default MercadoPago;
