import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { IObjectFieldProperty } from '../../model/IJsonSchema';
import objectFieldTemplateStyles from './objectFieldTemplateStyles';

export interface IObjectFieldProps {
  properties: IObjectFieldProperty[];
  title: string;
  idSchema: {
    $id: string;
  };
}

export const ObjectFieldTemplate: FunctionComponent<IObjectFieldProps> = props => {
  const styles = objectFieldTemplateStyles();
  const { properties } = props;

  const renderObjectFieldTemplate = () => {
    return properties?.map((field: IObjectFieldProperty, index: number) => {
      const fieldUiSchema = field?.content?.props?.uiSchema?.['ui:options'];
      const width =
        typeof fieldUiSchema?.width === 'string' ? parseInt(fieldUiSchema?.width, 10) : fieldUiSchema?.width;
      const isHidden = !!fieldUiSchema?.hide;

      if (isHidden) {
        return null;
      }

      return (
        <Grid item key={index} xs={12} sm={width} className={styles.child}>
          {field.content}
        </Grid>
      );
    });
  };

  return (
    <>
      <Grid container className={styles.root}>
        {renderObjectFieldTemplate()}
      </Grid>
    </>
  );
};

export default ObjectFieldTemplate;
