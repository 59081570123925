import React, { FunctionComponent, useMemo } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import Translation from '../../../../common/components/presentation/Translation/Translation';
import softLockModalStyles from './softLockModalStyles';
import ModalWithHeader from '../../../../common/components/presentation/ModalWithHeader/ModalWithHeader';
import { useGqlNav } from '../../../common/hooks/useGqlNav';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import usePlatformConfig from '../../../../common/react/usePlatformConfig';
import ShowIfTranslationExists from '../../../../common/components/ShowIfTranslationExists';
import useGetConfigurationUrl from '../../../common/hooks/useGetConfigurationUrl';
import useUserComingFromMyAccount from '../../hooks/useUserComingFromMyAccount';

interface ISoftLockModalProps {
  isOpen: boolean;
  softLockedErrorMsg?: string;
  isErrorPaymentInitSoftLock?: boolean;
  handleContinueBtn?: () => void;
  onClose?: () => void;
}

const SoftLockModal: FunctionComponent<ISoftLockModalProps> = ({
  handleContinueBtn,
  onClose,
  isOpen,
  softLockedErrorMsg,
  isErrorPaymentInitSoftLock,
}) => {
  const styles = softLockModalStyles();
  const intl = useIntl();
  const { history } = useGqlNav();
  const {
    platformConfig: {
      platformFeatures: { hideSoftLockHeaderTitle },
    },
  } = usePlatformConfig();

  const { getConfigurationUrl } = useGetConfigurationUrl();
  const { isUserComingFromMyAccount } = useUserComingFromMyAccount();

  const handleBackButton = () => {
    if (isUserComingFromMyAccount) {
      history.goBack();
    } else {
      window.location.href = getConfigurationUrl();
    }
  };

  const errorMessage = useMemo(() => {
    if (hideSoftLockHeaderTitle) {
      if (isErrorPaymentInitSoftLock) {
        return softLockedErrorMsg;
      }
      return 'trans__checkout__out_of_stock_modal__error_message_label';
    }

    return 'trans__checkout__out_of_stock_modal__customer_message';
  }, [hideSoftLockHeaderTitle, isErrorPaymentInitSoftLock]);

  const headerTitle = hideSoftLockHeaderTitle ? null : (
    <Translation id={'trans__checkout__out_of_stock_modal__error_message_label'} />
  );

  return (
    <ModalWithHeader open={isOpen} title={headerTitle} data-test="modal:soft_lock" toggleModal={onClose}>
      <Grid container className={styles.root} data-test="modal:soft_lock:content">
        <ShowIfTranslationExists translationKey={errorMessage}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: errorMessage,
              }),
            }}
            className={styles.message}
            data-test="modal:soft_lock:errorMessage"
          />
        </ShowIfTranslationExists>
        <ShowIfTranslationExists translationKey="trans__checkout__out_of_stock_modal__error_message_label2">
          <Typography
            className={styles.additionalMessage}
            data-test="modal:soft_lock:errorMessage2"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'trans__checkout__out_of_stock_modal__error_message_label2',
              }),
            }}
          />
        </ShowIfTranslationExists>
        <Grid item xs={12} md={6} className={styles.buttonContainer}>
          <Button
            className={styles.button}
            data-test="button:soft_lock_back"
            onClick={handleBackButton}
            variant="outlined"
          >
            <Translation id="trans__checkout__out_of_stock_modal__back_CTA" />
          </Button>
          {handleContinueBtn && (
            <Button
              className={clsx(styles.button, styles.softLockContinueBtn)}
              data-test="button:soft_lock_continue"
              onClick={handleContinueBtn}
              variant="outlined"
            >
              <Translation id="trans__checkout__next_step__continue_CTA" />
            </Button>
          )}
        </Grid>
      </Grid>
    </ModalWithHeader>
  );
};

export default SoftLockModal;
