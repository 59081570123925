import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import failedPaymentErrorStyles from './failedPaymentErrorStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';
import { useIntl } from 'react-intl';
import PaymentSummary from '../../../../../gql/checkout4/components/payment/paymentSummary/PaymentSummary';
import usePlatformConfig from '../../../../../common/react/usePlatformConfig';

const FailedPaymentError = () => {
  const {
    checkoutLite: {
      confirmation: { showFailedPaymentIcon, showInlineFailedPaymentIcon },
    },
  } = useTheme<ICheckoutTheme>();
  const {
    platformConfig: {
      checkout: { showSecureDisclaimerTextOnFailedPayment },
    },
  } = usePlatformConfig();
  const styles = failedPaymentErrorStyles();
  const intl = useIntl();

  return (
    <Box className={styles.container}>
      {showSecureDisclaimerTextOnFailedPayment && <PaymentSummary />}
      {showFailedPaymentIcon && (
        <Box display="flex">
          <Cancel className={styles.icon} />
        </Box>
      )}
      <Typography variant="subtitle1" data-test="payment_page:payment_error:subtitle" className={styles.subTitle}>
        {showInlineFailedPaymentIcon && <Cancel className={styles.icon} />}
        <Translation id="trans__checkout__payment_page__failed_subtitle" />
      </Typography>
      <Typography
        component="div"
        variant="body1"
        data-test="payment_page:payment_error:description1"
        className={styles.description}
      >
        <Translation
          id="trans__checkout__payment_page__failed_description1"
          values={{
            email: (
              <a
                className={styles.email}
                href={`mailto:${intl.formatMessage({
                  id: 'trans__checkout__confirmation_page__email_on_description_reserve_online_journey',
                })}`}
              >
                <Translation id={'trans__checkout__confirmation_page__email_on_description_reserve_online_journey'} />
              </a>
            ),
          }}
        />
      </Typography>
    </Box>
  );
};

export default FailedPaymentError;
