import { useEffect, useState, useMemo } from 'react';
import useSalesEnablementContext from '../../../../src/gql/common/hooks/useSalesEnablementContext';
import useCheckoutContext from '../../../../src/gql/common/hooks/useCheckoutContext';
import { DealStatus } from '../../checkout4/enum/DealStatus';
import storageService from '../../../../../common/service/storageService';

interface DealStatusObject {
  exported: string;
  dealId: string | null;
}

const useCheckDealStatus = () => {
  const VALID_STATUSES: DealStatus[] = [
    DealStatus.AUTHORIZED,
    DealStatus.CONTINUE_IN_DEALERSHIP_DETAILS_PROVIDED,
    DealStatus.RESERVE_ONLINE_DETAILS_PROVIDED,
    DealStatus.FAILED,
  ];

  const STATUS_OBJECT_KEY = 'export_details';
  const { deal } = useCheckoutContext();
  const { isInSalesEnablement, isDealExported } = useSalesEnablementContext();
  const isValid = deal && VALID_STATUSES.includes(deal.status as DealStatus);
  const currentDealId = deal?.uuid;
  const exportCounter = deal?.exportCount;
  const isDealExportValid = (isValid && isInSalesEnablement) || isDealExported || exportCounter !== 0;

  const getStoredObject = useMemo(() => {
    return (): DealStatusObject => {
      const storedObjectString = storageService.get(STATUS_OBJECT_KEY);
      return storedObjectString ? JSON.parse(storedObjectString) : { exported: 'false', dealId: null };
    };
  }, [STATUS_OBJECT_KEY]);

  const [storedIsDealExportValid, setIsDealExportValid] = useState(getStoredObject().exported === 'true');

  const handleStorageValidation = (currentDealId: string | null): void => {
    const storedObjectString = storageService.get(STATUS_OBJECT_KEY);

    if (storedObjectString) {
      const storedObject: DealStatusObject = JSON.parse(storedObjectString);

      if (storedObject.dealId !== currentDealId || !isInSalesEnablement) {
        storageService.remove(STATUS_OBJECT_KEY);
      }
    }
  };

  const handleStorageSave = (currentDealId: string, isDealExportValid: boolean): void => {
    const storedExportDetails = getStoredObject();

    if (!(currentDealId === storedExportDetails.dealId && storedExportDetails.exported === 'true')) {
      const storageObject: DealStatusObject = {
        exported: String(!!isDealExportValid),
        dealId: currentDealId || null,
      };

      isInSalesEnablement && storageService.set(STATUS_OBJECT_KEY, JSON.stringify(storageObject));
    }
  };

  useEffect(() => {
    if (currentDealId && isValid !== null) {
      handleStorageValidation(currentDealId);
      handleStorageSave(currentDealId, isDealExportValid);

      setIsDealExportValid(getStoredObject().exported === 'true');
    }
  }, [currentDealId, isDealExportValid]);

  return { storedIsDealExportValid };
};

export default useCheckDealStatus;
