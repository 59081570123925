import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from 'src/common/theme/ICheckoutTheme';

export type AccesoriesClasses =
  | 'root'
  | 'image'
  | 'container'
  | 'checkIcon'
  | 'button'
  | 'primaryButton'
  | 'textContent'
  | 'title'
  | 'grid'
  | 'header'
  | 'primaryButtonCaption'
  | 'secondaryButtonCaption'
  | 'continueButtonContainer';

const accesoriesStyles = makeStyles<ICheckoutTheme, AccesoriesClasses>(
  ({ spacing, breakpoints, palette }) => ({
    root: {
      margin: `${spacing(2)} 0`,
    },
    grid: {
      margin: '10px 0',
      flexDirection: 'row-reverse',
      [breakpoints.down('sm')]: {
        margin: 0,
      },
    },
    image: {
      maxWidth: '100%',
    },
    container: {
      display: 'flex',
      padding: `0 ${spacing(2)}`,
      flexDirection: 'column',
    },
    checkIcon: {
      color: 'green',
      marginRight: spacing(0.5),
    },
    title: {
      margin: `${spacing(1)} 0`,
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      marginBottom: spacing(1),
    },
    primaryButton: {},
    primaryButtonCaption: {
      color: palette.common.white,
    },
    secondaryButtonCaption: {
      color: palette.primary.main,
    },
    header: {
      marginLeft: spacing(3),
    },
    textContent: {
      marginBottom: spacing(2),
    },
    continueButtonContainer: {
      paddingTop: 20,
    },
  }),
  { name: 'Checkout4_Accesories' },
);

export default accesoriesStyles;
