import React, { FunctionComponent, ReactNode } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import genericInputStyles, { GenericInputClasses } from './GenericInputStyles';
import clsx from 'clsx';
import { IconButton, InputAdornment, InputLabel, Typography, Box } from '@material-ui/core';
import { IRawError } from '../../model/IJsonSchema';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { RenderInputParams } from '@material-ui/lab';
import HelpIcon from '@material-ui/icons/Help';
import TextTooltip from '../../../../summary/components/common/TextTooltip/TextTooltip';
import Translation from '../../../../common/components/presentation/Translation/Translation';
import usePlatformConfig from '../../../../common/react/usePlatformConfig';
import useWidgetContext from '../../../../common/react/useWidgetContext';
import usePriceFormatter from '../../../../../../common/react/usePriceFormatter';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

interface IGenericInputProps {
  label: string;
  required: boolean;
  id: string;
  value: string;
  currentError?: IRawError;
  onChange?: (e: React.ChangeEvent<{ value: string }>) => void;
  className?: string;
  inputLabel?: boolean;
  type?: string;
  datePicker?: boolean;
  toggleDatePicker?: (value: boolean) => void;
  params?: RenderInputParams;
  tooltip?: string;
  placeholder?: string;
  phoneWithPrefix?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  endAdornment?: ReactNode;
  'data-test'?: string;
  inputMode?: InputModes;
  displayCurrency?: boolean;
  showRequiredInPlaceholder?: boolean;
  classes?: Partial<ClassNameMap<GenericInputClasses>>;
}

export type InputModes = TextFieldProps['inputMode'];

export const GenericInput: FunctionComponent<IGenericInputProps> = ({
  label,
  required,
  id,
  onChange,
  value,
  currentError,
  inputLabel,
  type,
  datePicker,
  toggleDatePicker,
  phoneWithPrefix,
  params,
  tooltip,
  placeholder,
  readOnly,
  disabled,
  endAdornment,
  'data-test': dataTest,
  inputMode,
  displayCurrency,
  showRequiredInPlaceholder,
  classes,
}) => {
  const styles = genericInputStyles();

  const {
    platformConfig: {
      priceDisplay: {
        primary: { currency },
      },
    },
  } = usePlatformConfig();
  const {
    configuration: { dimensions },
  } = useWidgetContext();

  const priceFormatter = usePriceFormatter({
    currency: currency,
    decimals: 0,
    dimensions: {
      country: dimensions.country,
      language: dimensions.language,
    },
  });

  const displayRequiredInPlaceholder =
    showRequiredInPlaceholder !== undefined ? showRequiredInPlaceholder && required : required;

  return (
    <div ref={params?.InputProps?.ref} className={styles.fieldWrapper}>
      {inputLabel !== false && (
        <Box display={'flex'}>
          <Typography component="span" variant="subtitle1">
            <InputLabel htmlFor={id} required={required} className={styles.label} data-test={`text_input:label:${id}`}>
              {label}
            </InputLabel>
          </Typography>
          {tooltip && (
            <TextTooltip
              id={id}
              text={tooltip}
              content={
                <Box component="span">
                  <HelpIcon className={styles.tooltipIcon} color="primary" viewBox="0 0 28 28" />
                </Box>
              }
            />
          )}
        </Box>
      )}
      <TextField
        inputMode={inputMode}
        data-test={dataTest || `text_input:${id}`}
        id={id}
        type={type}
        variant="outlined"
        className={clsx([styles.input, { [styles.inputNoLabel]: inputLabel == false }])}
        placeholder={placeholder ?? `${label}${displayRequiredInPlaceholder ? ' *' : ''}`}
        value={value ?? ''}
        error={!!currentError}
        onChange={onChange}
        disabled={disabled}
        helperText={
          currentError && (
            <Typography component="span" className={styles.errorText} data-test={`text_input:error:${id}`}>
              {currentError}
            </Typography>
          )
        }
        InputProps={{
          readOnly: readOnly,
          classes: {
            root: styles.root,
            focused: styles.focused,
            error: styles.inputError,
            notchedOutline: styles.notchedOutline,
            disabled: styles.disabled,
          },
          endAdornment: endAdornment ? (
            endAdornment
          ) : (
            <InputAdornment position="end">
              {datePicker && (
                <IconButton onClick={() => toggleDatePicker(true)} component="span" className={styles.dateCalendar}>
                  <DateRangeIcon fontSize="small" />
                </IconButton>
              )}
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start" className={clsx([styles.phonePrefixBox, classes?.startAdornment])}>
              {phoneWithPrefix && (
                <span className={styles.phonePrefix}>
                  <Translation id={'trans__checkout__enquiry_form__phone_prefix'} />
                </span>
              )}
              {displayCurrency && priceFormatter.format(0).replace('0', '')}
            </InputAdornment>
          ),
        }}
        FormHelperTextProps={{
          classes: {
            root: styles.helperTextError,
          },
        }}
        {...params?.inputProps}
      />
    </div>
  );
};

export default GenericInput;
