import React, { FunctionComponent } from 'react';
import { Grid, Box } from '@material-ui/core';
import ContinueButton from '../buttons/continueButton/ContinueButton';
import accesoriesStyles from './accesoriesStyles';

const Accesories: FunctionComponent = () => {
  const styles = accesoriesStyles();

  return (
    <Grid container spacing={2} data-test={'checkout:accesories'}>
      {/* TODO: Creation of accesories lists and categories goes here */}
      <Box className={styles.continueButtonContainer}>
        <ContinueButton testId="checkout:continue_button:accesories" activateNextStep={true} />
      </Box>
    </Grid>
  );
};

export default Accesories;
